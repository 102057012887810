import React from "react";

import PropTypes from "prop-types";
import "./smallStepper.scss";
import { ASSESSMENT_TYPE_BASIC } from "../../common/constants/assessmentStatuses";

class SmallStepper extends React.Component {
  constructor(props) {
    super(props);
    const lastStep =
      this.props.assessmentType === ASSESSMENT_TYPE_BASIC
        ? "تأكيد والإرسال"
        : "الاضافات و الاقرارات";
    this.state = {
      activeStep: this.props.activeIndex,
      steps: [
        ...this.props.standardCategories,
        {
          name: lastStep,
        },
      ],
    };
  }

  nextStep = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  render() {
    const { activeIndex, tabs, standardCategories } = this.props;
    const { steps } = this.state;

    return (
      <div className="stepper-horizontal" id="stepper1">
        {steps
          .sort((a, b) => a.order - b.order)
          .map((step, i) => (
            <div
              className={`step ${activeIndex === i ? "editing" : ""}${
                activeIndex > i ? "done" : ""
              }`}
              key={i}
            >
              <div className="step-circle">
                <span>{i + 1}</span>
              </div>
              <div className="step-title">{step.name}</div>
              <div className="step-bar-left"></div>
              <div className="step-bar-right"></div>
            </div>
          ))}
      </div>
    );
  }
}
SmallStepper.propTypes = {
  standardCategories: PropTypes.array.isRequired,
  tabs: PropTypes.array,
  activeIndex: PropTypes.number,
};
export default SmallStepper;
