import React from "react";
import PropTypes from "prop-types";

import { Col, Row } from "reactstrap";

import { DEFAULT_NITAQAT_COLOR } from "../establishmentprofile/EstablishmentProfilePage";

const RED = "red";
const YELLOW = "#ffe300";
const GREEN = "green";

//class not used
const EstablishmentScoreList = ({ report, establishmentProfile }) => {
  const nitaqColorCode = `#${
    establishmentProfile.color_code || DEFAULT_NITAQAT_COLOR
  }`;
  const roundedFinalScore = Math.round(+report.final_result);

  let resultColor;
  let penaltyColor;
  let closingDaysColor;

  if (report.closing_days > 0) {
    resultColor = RED;
    penaltyColor = RED;
    closingDaysColor = RED;
  } else {
    closingDaysColor = GREEN;

    if (report.final_result < 30) {
      resultColor = RED;
    } else if (report.final_result > 75) {
      resultColor = GREEN;
    } else {
      resultColor = YELLOW;
    }

    if (report.max_penalty > 0) {
      if (resultColor === GREEN || resultColor === YELLOW) {
        penaltyColor = YELLOW;
      } else {
        penaltyColor = RED;
      }
    } else {
      penaltyColor = GREEN;
    }
  }

  return (
    <Row>
      <Col
        style={{
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          maxWidth: "1400px",
          margin: "0 auto",
        }}
      >
        <Row
          style={{
            margin: "170px -15px 100px",
            textAlign: "center",
            flex: "3 1 auto",
          }}
        >
          <Col className="report-head-col">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "rgb(77, 77, 77)",
              }}
            >
              نسبة الإمتثال
            </div>
            <div className="report-head-val" style={{ color: resultColor }}>
              {"%"}
              {Math.round(report.final_result)}
            </div>
          </Col>

          <Col className="report-head-col">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "rgb(77, 77, 77)",
              }}
            >
              مجموع الغرامات المتوقعة في حال عدم التصحيح
            </div>
            <div className="report-head-val" style={{ color: penaltyColor }}>
              {report.max_penalty.toString()}
              <div style={{ fontSize: "24px", lineHeight: "24px" }}>
                ريال سعودي
              </div>
            </div>
          </Col>

          <Col className="report-head-col">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "rgb(77, 77, 77)",
              }}
            >
              عدد أيام الإغلاق المتوقعة في حال عدم التصحيح
            </div>
            <div
              className="report-head-val"
              style={{ color: closingDaysColor }}
            >
              {report.closing_days}
            </div>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: "3px solid #679b27",
            margin: "0 auto",
            width: "40%",
          }}
        />

        <Row className="result-scales">
          {report.standard_categories_scores.map(({ name, score, weight }) => (
            <Col key={name} style={{ padding: "0 20px" }}>
              <div
                style={{
                  paddingBottom: "10px",
                  textAlign: "right",
                  fontSize: "20px",
                  color: "#4d4d4d",
                }}
              >
                {name}
              </div>
              <div>
                <div className="result-completion-wrap">
                  <div className="result-completion">
                    <div className="completion-track">
                      <div
                        style={{ width: `${+(score / weight) * 100}%` }}
                        className="completion-fill"
                      >
                        <div
                          className="completion-bg-color"
                          style={{
                            transform: `translateY(-${
                              (score / weight) * 100
                            }%)`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      lineHeight: "24px",
                      float: "left",
                      color: "#e0e0e0",
                    }}
                  >
                    %100
                  </div>
                  <div style={{ lineHeight: "24px", textAlign: "right" }}>
                    %{Math.round((score / weight) * 100)}
                  </div>
                </div>
              </div>
            </Col>
          ))}

          <Col>
            <div
              style={{
                paddingBottom: "10px",
                textAlign: "right",
                fontSize: "20px",
                fontWeight: "bold",
                color: "#4d4d4d",
              }}
            >
              المجموع
            </div>
            <div>
              <div className="result-completion-wrap">
                <div className="result-completion">
                  <div className="completion-track">
                    <div
                      style={{ width: `${+roundedFinalScore}%` }}
                      className="completion-fill"
                    >
                      <div
                        className="completion-bg-color"
                        style={{
                          transform: `translateY(-${roundedFinalScore}%)`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    lineHeight: "24px",
                    float: "left",
                    color: "#e0e0e0",
                  }}
                >
                  %100
                </div>
                <div style={{ lineHeight: "24px", textAlign: "right" }}>
                  %{Math.round(roundedFinalScore)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EstablishmentScoreList.propTypes = {
  report: PropTypes.object.isRequired,
  establishmentProfile: PropTypes.object,
};

export default EstablishmentScoreList;
