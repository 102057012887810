import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Row } from "reactstrap";
import { DESCRIPTION } from "../../common/constants/paymentStatuses";

//class not used
class EstablishmentServiceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collapsed !== nextProps.collapsed) {
      this.setState({ collapsed: nextProps.collapsed });
    }
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  componentWillMount(props) {
    const { isOpenByDefault } = this.props;

    if (isOpenByDefault) {
      this.setState({ collapsed: false });
    }
  }

  render() {
    const { service, payForService } = this.props;
    const { collapsed } = this.state;

    return (
      <Col>
        <Row style={{ padding: "30px" }}>
          <Col md={9}>
            {/*<Label className="label-caliberCategory d-flex justify-content-center report-green-title">{STATUSES[service.type]}</Label>*/}
            <Row>
              <span className="label-caliberCategory d-flex justify-content-right service-green-title service-description">
                {DESCRIPTION[service.type]}
              </span>
            </Row>
            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;المبلغ الكلي للخدمة :{" "}
              </span>{" "}
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;{` ${service.amount} ريال`}&nbsp;
              </span>
            </Row>
            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;القيمة المضافة :{" "}
              </span>
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;{` ${service.amount * service.vat} ريال`}&nbsp;
              </span>
            </Row>
          </Col>
          <Col md={3}>
            <div
              className="report-head-val"
              style={{ color: "red", float: "left" }}
            >
              {`${service.amount * service.vat + service.amount}`}
              <div style={{ fontSize: "12px", lineHeight: "12px" }}>
                ريال سعودي
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div
              className="horizontal-line"
              style={{ marginTop: 10, borderTop: "1px solid #679b27" }}
            ></div>
          </Col>
        </Row>

        <div
          className="report-head-val"
          style={{
            color: "red",
            paddingLeft: "20px",
            paddingRight: "20px",
            float: "left",
          }}
        >
          <Button
            color="primary"
            className="btn-round"
            onClick={() => {
              payForService(service.id);
            }}
          >
            ادفع
          </Button>
        </div>
      </Col>
    );
  }
}

EstablishmentServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  payForService: PropTypes.func,
};

export default EstablishmentServiceCard;
