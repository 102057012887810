import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as beforeLoadActions from "../../actions/beforeLoadActions";
import { requestToken } from "../../actions/authActions";
import * as profileActions from "../../actions/establishmentProfileActions";
import queryString from "query-string";
import Lottie from "react-lottie";
import * as animationLoader from "../../../public/animation/loading.json";

export class QiwaSSORedirectPage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }


  componentDidMount() {
    const param = queryString.parse(this.props.location.search);
    
    requestToken(param.code, param.state)
      .then((token) => {
        if (token) {
          this.props.actions.logIn(token);
          const selectedEstablishment = localStorage.getItem("selected");
          if (selectedEstablishment) {
            this.props.saveSelectedEstablishment(JSON.parse(selectedEstablishment));
          }
  
          // Return the promise from getUserProfile to chain it
          return this.props.actions.getUserProfile();
        }
        // If token is null, resolve with null to maintain chaining
        return null;
      })
      .then((userInfo) => {
        console.log("After requestToken, the user info is:", userInfo);
        this.redirect(userInfo); // Ensure redirect is called after promises resolve
      })
      .catch((error) => {
        console.error("Error during token request or profile retrieval:", error);
        // Handle errors appropriately
      });
  }
  
  

  redirect(userInfo) {
    const establishmentProfile = JSON.parse(localStorage.getItem("selected"));

    if (establishmentProfile) {
      const isProfileFirstLogin =
        establishmentProfile.id && !establishmentProfile.is_confirmed;
      if (
        isProfileFirstLogin ||
        (userInfo && userInfo.is_email_editable && userInfo.email === "") ||
        (userInfo && userInfo.is_email_editable && !userInfo.email)
      ) {
        this.props.history.push("/establishmentProfile");
      } else {
        this.props.history.push("/establishmentBasicDurationsPage");
      }
    } else {
      this.props.history.push("/establishmentProfile");  
    }
  }

  render() {
    return (
      <div>
        <div className="loading-screen">
          <div className="loader-div">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={160}
              width={160}
            />
            <p className="loader-text">يرجى الانتظار..</p>
          </div>
        </div>
      </div>
    );
  }
}

QiwaSSORedirectPage.propTypes = {
  state: PropTypes.object,
  history: PropTypes.object,
};

QiwaSSORedirectPage.contextTypes = {
  actions: PropTypes.object,
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    state: state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(beforeLoadActions, dispatch),
    saveSelectedEstablishment: (establishment) =>
      dispatch(profileActions.loadEstablishmentProfile(establishment)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QiwaSSORedirectPage);
