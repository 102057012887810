import React from "react";
import PropTypes from "prop-types";
import "./carouselSlider.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

const CarouselSlider = (
  type = "square",
  labelUnderlined = false,
  onChooseCaliber,
  caliberCategories
) => {
  const onClickHandler = (caliber) => {
    onChooseCaliber(caliber);
  };
  const renderSlides = () =>
    caliberCategories?.map((caliber) => (
      <div key={caliber.id} className="carousel-item">
        <button
          onClick={(e) => onClickHandler(caliber)}
          className="carousel-item-button"
        >
          <Text textType="h8" className="carousel-item-text">
            {caliber.label}
          </Text>
        </button>
      </div>
    ));

  return (
    <div className="carousel">
      <Slider
        dots
        slidesToShow={3}
        slidesToScroll={3}
        speed={800}
        infinite
        rtl
        centerPadding="50px"
      >
        {renderSlides()}
      </Slider>
    </div>
  );
};

CarouselSlider.propTypes = {
  caliberCategories: PropTypes.array,
  onChooseCaliber: PropTypes.func,
};

export default CarouselSlider;
