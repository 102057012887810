import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { STATUSES } from "../../../common/constants/paymentStatuses";
import "./invoicePdf.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import QRCode from "react-qr-code";

const InvoicePdf = (props) => {
  const {
    invoice,
    establishmentName,
    establishmentAddress,
    establishmentTaxNumber,
  } = props;

  return (
    <div className="invoice-pdf">
      <div className="invoice-pdf-wrapper">
        <div className="flex-1 ">
          <div
            className="invoice-pdf-header "
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="invoice-pdf-header__logo">
              <img
                src={"img/IMS-MHRSD-logo.svg"}
                className="invoice-pdf-header__logo"
                alt="MOL logo"
              />
            </div>
            {invoice && (
              <Text
                Tag="div"
                textType="h11"
                className="invoice-pdf-header__text"
              >
                رقم الفاتورة: #{invoice.payment_resp_bill_number}
              </Text>
            )}
          </div>
          <div className="invoice-pdf-horizontal-line" />
          <div className="invoice-pdf-background-image">
            <img
              src={"img/mhrsd-background-logo.svg"}
              className="invoice-pdf-background-image"
              alt="MOL logo"
            />
          </div>
          {invoice && (
            <div
              className="invoice-pdf-content invoice-pdf-content__first-section"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="invoice-pdf-text">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.2in",
                  }}
                >
                  <Text
                    Tag="span"
                    textType="p6"
                    className="invoice-pdf-text__label"
                  >
                    تاريخ إصدار الفاتورة:
                  </Text>
                  <Text
                    Tag="span"
                    textType="p6"
                    isBold
                    className="invoice-pdf-text"
                  >
                    {`${moment(invoice.created_at).format("DD/MM/YYYY")}`}
                  </Text>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                    Tag="span"
                    textType="p6"
                    className="invoice-pdf-text__label"
                  >
                    وقت إصدار الفاتورة:
                  </Text>
                  <Text
                    Tag="span"
                    textType="p6"
                    isBold
                    className="invoice-pdf-text"
                  >
                    {`${moment(invoice.created_at).format("LT")}`}
                  </Text>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 0.2,
                }}
              >
                <QRCode
                  style={{ float: "left" }}
                  value={`${process.env.REACT_APP_API_URI}/#/billInfo/${invoice.id}`}
                  size={128}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  // includeMargin={false}
                  // renderAs={"svg"}
                />
              </div>
            </div>
          )}
          <div
            className="invoice-pdf-content invoice-pdf-content__first-section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="invoice-pdf-text">
              <Text
                Tag="div"
                textType="p6"
                isBold
                className="invoice-pdf-text invoice-pdf-text__title"
              >
                مزود الخدمة:
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "0.2in",
                }}
              >
                <Text
                  Tag="span"
                  textType="p6"
                  className="invoice-pdf-text__label"
                >
                  الاسم:
                </Text>
                <Text
                  Tag="span"
                  textType="p6"
                  isBold
                  className="invoice-pdf-text"
                >
                  {invoice.name}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "0.2in",
                }}
              >
                <Text
                  Tag="div"
                  textType="p6"
                  className="invoice-pdf-text__label"
                >
                  الرقم الضريبي:
                </Text>
                {invoice && (
                  <Text Tag="div" textType="p6" className="invoice-pdf-text">
                    {invoice.tax_number}
                  </Text>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  Tag="div"
                  textType="p6"
                  className="invoice-pdf-text__label"
                >
                  العنوان:
                </Text>

                <Text Tag="div" textType="p6" className="invoice-pdf-text">
                  الرياض - المملكة العربية السعودية
                </Text>
              </div>
            </div>

            <div className="invoice-pdf-text">
              <div className="invoice-pdf-text__second-column">
                <Text
                  Tag="div"
                  textType="p6"
                  isBold
                  className="invoice-pdf-text invoice-pdf-text__title"
                >
                  العميل:
                </Text>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.2in",
                  }}
                >
                  <Text
                    Tag="div"
                    textType="p6"
                    className="invoice-pdf-text__label"
                  >
                    الاسم:
                  </Text>
                  {establishmentName && (
                    <Text
                      Tag="div"
                      textType="p6"
                      isBold
                      className="invoice-pdf-text"
                    >
                      {establishmentName}
                    </Text>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.2in",
                  }}
                >
                  <Text
                    Tag="div"
                    textType="p6"
                    className="invoice-pdf-text__label"
                  >
                    الرقم الضريبي:
                  </Text>
                  {establishmentTaxNumber && (
                    <Text Tag="div" textType="p6" className="invoice-pdf-text">
                      {establishmentTaxNumber}
                    </Text>
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                    Tag="div"
                    textType="p6"
                    className="invoice-pdf-text__label"
                  >
                    العنوان:
                  </Text>
                  {establishmentAddress && (
                    <Text Tag="div" textType="p6" className="invoice-pdf-text">
                      {establishmentAddress.split(",").map((str) => (
                        <div key={str}>{str}</div>
                      ))}
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="invoice-pdf-content invoice-pdf-content__second-section"
            style={{ display: "flex", flexDirection: "row" }}
          ></div>
          <div className="invoice-pdf-table__margin">
            <div
              className="invoice-pdf-table invoice-pdf-table__header"
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <Text Tag="div" textType="p6" isBold className="flex-1">
                نوع الخدمة
              </Text>
              <Text Tag="div" textType="p6" isBold className="flex-1">
                المبلغ
              </Text>
              <Text Tag="div" textType="p6" isBold className="flex-1">
                القيمة المضافة {invoice.vat ? `${invoice.vat * 100}%` : ""}
              </Text>
              <Text Tag="div" textType="p6" isBold className="flex-1">
                المبلغ الإجمالي
              </Text>
            </div>
            <div
              className=" invoice-pdf-table invoice-pdf-table__data"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text
                Tag="div"
                textType="p6"
                className="flex-1"
                style={{
                  textAlign: "center",
                }}
              >
                {invoice.service_type ? STATUSES[invoice.service_type] : ""}
              </Text>
              {invoice.amount && (
                <Text
                  Tag="div"
                  textType="p6"
                  className="flex-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {invoice.amount}
                  <Text
                    fontSize={"font-26"}
                    textType="roman"
                    Tag="span"
                    style={{ lineHeight: 0.2 }}
                  >
                    &nbsp;$
                  </Text>
                </Text>
              )}

              {invoice.amount && (
                <Text
                  Tag="div"
                  textType="p6"
                  className="flex-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {invoice.amount * invoice.vat}
                  <Text
                    fontSize={"font-26"}
                    textType="roman"
                    Tag="span"
                    style={{ lineHeight: 0.2 }}
                  >
                    &nbsp;$
                  </Text>
                </Text>
              )}

              {invoice.totalAmount && (
                <Text
                  Tag="div"
                  textType="p6"
                  className="flex-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {invoice.totalAmount}
                  <Text
                    fontSize={"font-26"}
                    textType="roman"
                    Tag="span"
                    style={{ lineHeight: 0.2 }}
                  >
                    &nbsp;$
                  </Text>
                </Text>
              )}
            </div>
          </div>
        </div>

        <div className="invoice-pdf-footer">
          <div className="invoice-pdf-horizontal-line" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "15px",
            }}
          >
            <Text
              Tag="div"
              textType="h11"
              className="invoice-pdf-footer__text-left"
            >
              رقم التواصل: 920033411
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
InvoicePdf.propTypes = {
  invoice: PropTypes.object,
  establishmentName: PropTypes.string,
  establishmentAddress: PropTypes.string,
  establishmentTaxNumber: PropTypes.string,
};

export default InvoicePdf;
