import React from "react";

import PropTypes from "prop-types";
import "./radioButton.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";

class RadioButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleAuditorAnswerChange = this.handleAuditorAnswerChange.bind(this);
  }

  handleAuditorAnswerChange() {
    const { value, onChange } = this.props;
    onChange(value);
  }
  render() {
    const { label, value, selectedRadioButton, isError, icon } = this.props;
    return (
      <div
        className="radio-button-wrapper"
        onClick={this.handleAuditorAnswerChange}
      >
        <div
          className={`${
            isError ? "radio-button radio-button-error" : "radio-button"
          } ${
            selectedRadioButton === value ? "radio-button-checked-outer" : ""
          }`}
          data-value={value}
        >
          <div
            className={
              selectedRadioButton === value ? "radio-button-checked-inner" : ""
            }
            data-value={value}
          ></div>
        </div>
        <div
          className={
            isError
              ? "radio-button-label radio-button-label-error"
              : "radio-button-label"
          }
        >
          {icon && (
            <div className="radio-button-icon">
              <img src={icon} alt="cards icons" />
            </div>
          )}
          <Text Tag="div" textType="p4">
            {label}
          </Text>
        </div>
      </div>
    );
  }
}
RadioButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selectedRadioButton: PropTypes.string,
  isError: PropTypes.bool,
};
RadioButton.defaultProps = {
  isError: false,
};
export default RadioButton;
