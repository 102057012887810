import React from "react";
import PropTypes from "prop-types";
import "./signupInstructions.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import Button from "../../../components/Button/Button";
import Content from "../../../containers/Content/Content";

function SignupInstructions(props) {
  const logIn = () => {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  };
  return (
    <Content history={props.history} noFooter>
      <div className="signup-instructions">
        <div>
          <Text Tag="div" textType="h4" isBold className="si-title">
            مرحبًا بك عميلنا العزيز
          </Text>

          <div className="si-papper">
            <Text Tag="div" textType="h6" className="si-papper-title ">
              لتسجيل الدخول، يرجى التأكد من الخطوات التالية :
            </Text>
            <div className="si-items">
              <div className="si-item">
                <div className="si-item-circle ">
                  <Text
                    Tag="div"
                    textType="h5"
                    isBold
                    className="si-item-circle-text"
                  >
                    1
                  </Text>
                </div>
                <div className="si-item-text-wrapper ">
                  <Text Tag="div" textType="p2" className="si-item-text ">
                    وجود حساب في وزارة الموارد البشرية والتنمية الاجتماعية
                  </Text>
                  <Text Tag="div" textType="p5" className="si-item-text ">
                    في حال عدم وجود حساب ، يمكنك{" "}
                    <a
                      href="https://www.mol.gov.sa/IndividualUser/BasicInfo.aspx"
                      style={{
                        color: "#1b8386",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      التسجيل ببوابة الخدمات الإلكترونية
                    </a>
                  </Text>
                </div>
              </div>
              <div className="si-item">
                <div className="si-item-circle ">
                  <Text
                    Tag="div"
                    textType="h5"
                    isBold
                    className="si-item-circle-text"
                  >
                    2
                  </Text>
                </div>
                <div className="si-item-text-wrapper ">
                  <Text Tag="div" textType="p2" className="si-item-text ">
                    التأكد من تفعيل رقم الجوال في الخدمات الإلكترونية لدى وزارة
                    الموارد البشرية والتنمية الاجتماعية
                  </Text>
                </div>
              </div>
              <div className="si-item">
                <div className="si-item-circle ">
                  <Text
                    Tag="div"
                    textType="h5"
                    isBold
                    className="si-item-circle-text"
                  >
                    3
                  </Text>
                </div>
                <div className="si-item-text-wrapper ">
                  <Text Tag="div" textType="p2" className="si-item-text ">
                    التأكد من تفعيل البريد الإلكتروني في الخدمات الإلكترونية لدى
                    وزارة الموارد البشرية والتنمية الاجتماعية
                  </Text>
                </div>
              </div>
              <div className="si-item">
                <div className="si-item-circle ">
                  <Text
                    Tag="div"
                    textType="h5"
                    isBold
                    className="si-item-circle-text"
                  >
                    4
                  </Text>
                </div>
                <div className="si-item-text-wrapper">
                  <Text Tag="div" textType="p2" className="si-item-text ">
                    وجود منشأة مفوض عليها
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="si-button">
            <Button
              onClick={logIn}
              text="بدء الخدمة"
              customStyle={"si-button-custom-style "}
              customTextSize="h7"
            />
          </div>
        </div>
      </div>
    </Content>
  );
}

SignupInstructions.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default SignupInstructions;
