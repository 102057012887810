import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CheckBox } from "../../../lib/components/checkbox";
import { useSetSteps, useSteps } from "../../self-assessment-form-context";
import { Tables } from "../table";
import { RE_ASSESSMENT } from "../../../../../../common/constants/paymentStatuses";
import { twMerge } from "../../../lib/tw-merge";
import { getTableHeader, getTableRows } from "./table-data";
import "./addons-with-agreements.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";

export const AddonsWithAgreements = ({
  isDetailedReportAllowed,
  serviceList,
  errors,
  assessmentType,
}) => {
  const [agreementOfData, setAgreementOfData] = useState(false);
  const [agreementOfEdit, setAgreementOfEdit] = useState(false);
  const [checkedService, setCheckedService] = useState(false);
  const [isLoadedAgreements, setIsLoadedAgreements] = useState(null);

  const steps = useSteps();
  const setSteps = useSetSteps();

  const hasAgreed = useMemo(() => {
    return agreementOfData && agreementOfEdit;
  }, [agreementOfData, agreementOfEdit]);
  const isError = errors?.agreement?.length ? true : false;
  const isCheckedService = useMemo(() => checkedService, [checkedService]);

  const handleAgreementChange = async (hasAgreed) => {
    const updatedSteps = [...steps];

    updatedSteps[updatedSteps?.length - 1] = {
      ...updatedSteps?.[updatedSteps?.length - 1],
      hasAgreed,
    };

    setSteps(updatedSteps);
    setIsLoadedAgreements(true);
  };

  const handleCheckboxChange = (isCheckedService) => {
    const updatedSteps = [...steps];
    updatedSteps[updatedSteps?.length - 1] = {
      ...updatedSteps?.[updatedSteps?.length - 1],
      isCheckedService,
    };
    setSteps(updatedSteps);
  };

  useEffect(() => {
    handleAgreementChange(hasAgreed);
  }, [hasAgreed]);

  useEffect(() => {
    //only runs on mount after handleAgreementChange finishes
    if (isLoadedAgreements) handleCheckboxChange(isCheckedService);
  }, [isLoadedAgreements]);

  useEffect(() => {
    //check isLoadedAgreements value, so handleCheckboxChange doesn't run while handleAgreementChange is running
    //only runs after checkbox is checked
    if (isLoadedAgreements) {
      handleCheckboxChange(isCheckedService);
    }
  }, [isCheckedService]);

  const isReAssessment = assessmentType === RE_ASSESSMENT;

  const totalAmount =
    serviceList[0]?.amount * serviceList[0]?.vat + serviceList[0]?.amount;
  //REMOVE COMMENT WHEN GRACE PERIOD SERVICE IS ACTIVATED AGAIN
  // isReAssessment
  //   ? serviceList[0]?.amount * serviceList[0]?.vat + serviceList[0]?.amount
  //   : serviceList.reduce((p, c) => p + c.amount * c.vat + c.amount, 0);
  return (
    <div className="flex flex-col text-start pr-[62px] pl-[72px] step-container-padding">
      {isDetailedReportAllowed && (
        <Fragment>
          <Text
            Tag={"div"}
            textType="bold"
            fontSize={"font-20"}
            mobileFontSize={"font-16-mobile"}
            className={"text-ims-deep_sea_blue"}
          >
            {"الاضافات"}
          </Text>
          <Text
            Tag={"div"}
            textType="roman"
            fontSize={"font-18"}
            mobileFontSize={"font-16-mobile"}
            style={{ color: "#000", textAlign: "justify" }}
            className="mt-6 leading-8"
          >
            يمكنك اضافة خدمة التقرير المفصل، يوضح التقرير المفصل الوضع الحالي
            للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها
            لرفع هذه النسبة لتفادي الغرامات المالية.
            {
              //REMOVE COMMENT WHEN GRACE PERIOD SERVICE IS ACTIVATED AGAIN
              /* {isReAssessment
              ? "يمكنك اضافة خدمة التقرير المفصل، يوضح التقرير المفصل الوضع الحالي للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها لرفع هذه النسبة لتفادي الغرامات المالية."
              : "يمكنك اضافة خدمتي التقرير المفصل و المهلة التصحيحية، يوضح التقرير المفصل الوضع الحالي للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها لرفع هذه النسبة لتفادي الغرامات المالية.بينما تتيح خدمة المهلة التصحيحية الحصول على مهلة تصحيحية مدتها شهرين لتأجيل الزيارات التفتيشية التابعة للوزارة."} */
            }
          </Text>
          <Text
            Tag={"div"}
            textType="bold"
            fontSize={"font-17"}
            mobileFontSize={"font-15-mobile"}
            style={{ color: "#000" }}
            className=" mt-4"
          >
            {`تكلفة الخدمة شامل الضريبة المضافة (15%) : ${totalAmount} `}
            <Text fontSize={"font-29"} textType="roman" Tag="span">
              $
            </Text>
          </Text>
          <div
            style={{
              overflow: "hidden",
              height: isCheckedService ? "auto" : 0,
              transition: "1s",
              maxHeight: "100%",
            }}
          >
            <span className="desktopTable ">
              <Tables
                type={"primary"}
                headerClss={"text-[#4F4F55] bg-ims-light_gray"}
                headerTitle={getTableHeader()}
                tableRows={getTableRows(serviceList, isReAssessment)}
              />
            </span>
            <span className="mobileTable ">
              <Tables
                type={"primary"}
                headerClss={"text-white bg-ims-20_turquoise_blue"}
                headerTitle={getTableHeader(true)}
                tableRows={getTableRows(serviceList, isReAssessment, true)}
              />
            </span>
          </div>
          {/* )} */}
          <div className="mt-8 flex gap-x-4">
            <CheckBox
              label={
                "أرغب في اضافة خدمة التقرير المفصل"

                //REMOVE COMMENT WHEN GRACE PERIOD SERVICE IS ACTIVATED AGAIN
                // isReAssessment
                //   ? "أرغب في اضافة خدمة التقرير المفصل"
                //   : "أرغب في اضافة خدمة التقرير المفصل وخدمة المهلة التصحيحية"
              }
              onChange={(value) => setCheckedService(value)}
              checked={isCheckedService}
              mobileFontSize={"font-16-mobile"}
            />
          </div>

          <div className="w-full h-[1px] bg-gray-100 my-10" />
        </Fragment>
      )}
      <Text
        Tag={"div"}
        textType="bold"
        fontSize={"font-20"}
        className={twMerge(
          "font-mol font-semibold text-ims-deep_sea_blue",
          isError && "text-red-500"
        )}
      >
        {"الاقرارات"}
      </Text>
      <div className={"mt-8 flex flex-col gap-y-4"}>
        <CheckBox
          label="أقر بأن جميع البيانات التي تم ادخالها وإرفاقها صحيحة و محدثة."
          onChange={(value) => setAgreementOfData(value)}
        />
        <CheckBox
          label="أوافق على أنه لا يمكن التعديل على النموذج في حال الارسال"
          onChange={(value) => setAgreementOfEdit(value)}
        />
      </div>

      {isError && (
        <Text className={"text-red-500 text-sm font-bold mt-4"}>
          {errors?.agreement}
        </Text>
      )}
      {errors?.missingAnswer && (
        <Text className={"text-red-500 text-sm font-bold mt-4"}>
          ** {errors?.missingAnswer}
        </Text>
      )}
    </div>
  );
};
