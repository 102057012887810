import React, { useEffect, useMemo, useState } from "react";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./assessment.scss";
import Summary from "./Summary";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { loadEstablishmentCurrentBasicAssessments } from "../../../actions/establishmentAssessmentActions";
import { useDispatch, useSelector } from "react-redux";
import {
  INSPECTION_REQUEST_STATUS_COMPLETED,
  INSPECTION_REQUEST_STATUS_NEW,
  INSPECTION_REQUEST_STATUS_INITIATED,
  INSPECTION_REQUEST_STATUS_ON_PROGRESS,
  RE_SUBMIT_STATUS_API_VALUE,
  STATUS_TIMEOUT,
  STATUS_EMPTY,
  STATUS_PARTIALLY_FILLED_API_VALUE,
  ASSESSMENT_TYPE_RE_ASSESSMENT,
} from "../../../common/constants/assessmentStatuses";
import Message from "./Message";
import Lottie from "react-lottie";
import * as animationLoader from "../../../../public/animation/loading.json";
import { saveSelectedDuration } from "../../../actions/durationActions";
import {
  ADDITIONAL_ASSESSMENT,
  RE_ASSESSMENT,
} from "../../../common/constants/paymentStatuses";
import { AssessmentFeatureCard, CardsGroupTitle } from "../../../components";
import { filterCards } from "./AdditionalServicesCards";
import { ReAssessmentCard } from "./ReAssessmentCard";
import { cancelInspectionRequest } from "../../../actions/beforeLoadActions";
import PopUp from "../../Components/PopUp/PopUp";
import "../../../components/AssessmentCards/featureCard.css";
// import { Checkbox } from "@code.tamkeen.cloud/ims-shared-components";

const BasicAssessment = (props) => {
  const dispatch = useDispatch();
  const establishmentProfile = useSelector((state) => {
    return state.establishment.establishmentProfile;
  });

  const [current, setCurrent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [haveActivePeriod, setHaveActivePeriod] = useState(false);
  const [isReassessmentBasicTrack, setIsReassessmentBasicTrack] =
    useState(false);
  const [isInspectionTriggered, setInspectionTriggered] = useState(false);
  const [isCancelInspection, setICancelInspection] = useState(false);

  const isReturnedAssessment =
    current &&
    current.have_active_period &&
    current.audit_status === RE_SUBMIT_STATUS_API_VALUE;

  const isStartFreeAssessment =
    current &&
    current.have_active_period &&
    current.is_free_assessment &&
    (current?.assessment_status === STATUS_EMPTY ||
      current?.assessment_status === STATUS_PARTIALLY_FILLED_API_VALUE);

  const assessmentCompleted =
    current?.assessment_status === "Completed" ||
    current?.assessment_status === "UnderGracePeriod" ||
    current?.assessment_status === "UnderInspection";

  const isPayForAssessment =
    current &&
    !isReturnedAssessment &&
    !assessmentCompleted &&
    current.have_active_period &&
    !current.is_free_assessment;

  const auditingCompleted =
    current?.audit_status === "Completed" ||
    current?.audit_status === "UnderGracePeriod" ||
    current?.audit_status === "UnderInspection";

  const isCompleted = current && !isReturnedAssessment && assessmentCompleted;

  const isReAssessmentSubmitted =
    current && isReassessmentBasicTrack && assessmentCompleted;

  const isContinueAssessment =
    current &&
    current.have_active_period &&
    current?.assessment_status === STATUS_PARTIALLY_FILLED_API_VALUE;

  const isUnderAuditing =
    current &&
    !auditingCompleted &&
    assessmentCompleted &&
    current.have_active_period;

  const isNewInspection =
    current &&
    current.inspection_assessment_status === INSPECTION_REQUEST_STATUS_NEW;

  const onCancelInspectionPopUp = (value) => {
    setICancelInspection(value);
  };

  const onCancelInspection = () => {
    setIsLoading(true);
    dispatch(
      cancelInspectionRequest(establishmentProfile.id, current.inspection_id)
    ).then(() => {
      setInspectionTriggered(!isInspectionTriggered);
    });
  };

  const onClose = () => {
    setShowMessage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (establishmentProfile.id) {
      setIsLoading(true);
      dispatch(
        loadEstablishmentCurrentBasicAssessments(establishmentProfile.id)
      ).then((result) => {
        if (
          result.basicTrack.assessment_type === ASSESSMENT_TYPE_RE_ASSESSMENT
        ) {
          setIsReassessmentBasicTrack(true);
        } else setIsReassessmentBasicTrack(false);
        setHaveActivePeriod(result.basicTrack.have_active_period);
        setCurrent(result.basicTrack);
        setIsLoading(false);
      });
    }
  }, [establishmentProfile, isInspectionTriggered]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { ordered, features } = useMemo(() => {
    if (establishmentProfile && current) {
      return filterCards({
        estProfile: establishmentProfile,
        isReturnedAssessment,
        current: current,
      });
    }
    return { ordered: [], features: [] };
  }, [establishmentProfile, isReturnedAssessment, current]);

  const isPeriodExpired = current && !current.have_active_period;

  const onClickButton = () => {
    const { history } = props;
    if (isReassessmentBasicTrack && !isReAssessmentSubmitted) {
      history.push(
        `/establishmentAssessment/${current.period_id}/${current.assessment_version}/true/${current.period_assessment_id}`
      );
    } else if (isReturnedAssessment) {
      history.push({
        pathname: `/establishmentAssessment/${current.period_id}/${current.assessment_version}/true/${current.period_assessment_id}`,
        state: { ...current, isReturned: true },
      });
    } else if (isStartFreeAssessment) {
      dispatch(saveSelectedDuration(current));
      history.push(
        `/establishmentAssessmentInstruction/${current.period_id}/${current.assessment_version}/true/${current.period_assessment_id}`
      );
    } else if (isPayForAssessment) {
      const withoutResult = true;
      dispatch(saveSelectedDuration(current));
      history.push({
        pathname: `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${establishmentProfile.id}/${current.assessment_version}/${withoutResult}/${ADDITIONAL_ASSESSMENT}/${current.period_assessment_id}`,
        state: { trackType: "basic" },
      });
      // history.push(
      //   `/establishmentAssessmentPayment/${current.period_id}/${current.assessment.id}/${establishmentProfile.id}/${current.assessment_version}/${withoutResult}/${ADDITIONAL_ASSESSMENT}/${current.period_assessment_id}`
      // );
    } else if (isCompleted || isUnderAuditing || isReAssessmentSubmitted)
      history.push(
        `/establishmentAssessmentResult/${current.period_id}/${current.assessment_id}/${establishmentProfile.id}/${current.assessment_version}`
      );
  };

  const reassessmentLink = useMemo(() => {
    if (current && establishmentProfile) {
      return `/establishmentAssessmentPayment/${current?.period_id}/${
        current?.assessment_id
      }/${establishmentProfile?.id}/${
        current?.assessment_version
      }/${false}/${RE_ASSESSMENT}/${current?.period_assessment_id}`;
    }

    return undefined;
  }, [current, establishmentProfile]);

  if (isLoading) {
    return (
      <PageWrapper withBreadcrumbs>
        <div className="loader-div">
          <Lottie options={defaultOptions} height={160} width={160} />
          <p className="assessment-submission-loading-text">يرجى الانتظار..</p>
        </div>
      </PageWrapper>
    );
  } else if (haveActivePeriod === false) {
    return (
      <PageWrapper>
        <div className="assessment-page">
          <h3 className={"text-2xl text-[#14415A] font-mol font-bold"}>
            {"التقييم الذاتي"}
          </h3>
          <div
            className={
              "mt-4 flex flex-col border border-[#E2E2EA] bg-white rounded w-full h-full flex-1 items-center justify-center min-h-[703px]"
            }
          >
            <img
              src="/img/EmptyStateList.svg"
              alt="empty state list"
              className={"object-fill object-center"}
            />
            <div className={"mt-10"}>
              <h3
                className={
                  "text-base font-mol font-semibold text-[#14415A] leading-relaxed max-w-xl text-center"
                }
              >
                {
                  "عذرا، هذه الخدمة غير متاحة حاليا، يمكنك الاستفادة من هذه الخدمة قريبًا عند بداية الفترة التقييمية الجديدة"
                }
              </h3>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper>
        <div className="assessment-page">
          <Text Tag="div" textType="h5" isBold className="assessment-title">
            التقييم الذاتي
          </Text>
          {isPeriodExpired ? (
            <div className="assessment-card"> الفترة منتهية</div>
          ) : (
            <div className="assessment-card">
              {showMessage &&
                (isReturnedAssessment ||
                  isPayForAssessment ||
                  (isReassessmentBasicTrack &&
                    !isCompleted &&
                    !isUnderAuditing) ||
                  isStartFreeAssessment ||
                  // (establishmentProfile.is_blocked &&
                  //   !isUnderAuditing &&
                  //   !isCompleted)
                  //    ||
                  isNewInspection) && (
                  <div className="assessment-message">
                    <Message
                      isReturnedAssessment={isReturnedAssessment}
                      isPayForAssessment={isPayForAssessment}
                      isStartFreeAssessment={isStartFreeAssessment}
                      // isBlocked={establishmentProfile.is_blocked}
                      showCloseButton={isStartFreeAssessment}
                      onClose={onClose}
                      endDate={current?.end_date}
                      startDate={current?.start_date}
                      returnedCaliberEndAt={current?.returned_caliber_end_date}
                      isReassessment={isReassessmentBasicTrack}
                      isNewInspection={isNewInspection}
                    />
                  </div>
                )}
              {/* {isReassessmentBasicTrack && (
                <ReAssessmentCard
                  title={"إعادة التقييم للمسار الاساسي"}
                  subTitle={
                    "يمكنك من خلال هذه الخدمة الحصول على شهادة امتثال لمنشأتك مقدمة من قِبل وزارة الموارد البشرية والتمنية الاجتماعية والتي يمكن اعتبارها كإثبات معتمد بأن المنشأة ملتزمة بالمعايير المحددة."
                  }
                  link={reassessmentLink}
                />
              )} */}
              <Summary
                isReturnedAssessment={isReturnedAssessment}
                isPayForAssessment={isPayForAssessment}
                isStartFreeAssessment={isStartFreeAssessment}
                isBlocked={establishmentProfile.is_blocked}
                isUnderAuditing={isUnderAuditing}
                isCompleted={isCompleted}
                isReassessment={isReassessmentBasicTrack}
                isReAssessmentSubmitted={isReAssessmentSubmitted}
                onClickButton={onClickButton}
                isContinueAssessment={isContinueAssessment}
                isNewInspection={isNewInspection}
                onCancelInspection={() => onCancelInspectionPopUp(true)}
              />

              {/* <Checkbox
                type="checkbox"
                checked={true}
                onChange={() => {}}
                disabled={false}
                id={"id-101"}
              /> */}
              <div className={"mt-16"} />
              <div
                className={
                  "flex flex-col items-start justify-start w-full feture-list"
                }
              >
                {ordered?.length > 0 && (
                  <div
                    className={
                      "flex flex-col gap-y-4 items-start justify-start feature-cards-section"
                    }
                  >
                    <CardsGroupTitle title={"الخدمات التي تم طلبها"} />
                    <div className={"flex items-center flex-wrap gap-4"}>
                      {ordered?.map?.((item, index) => {
                        return (
                          <AssessmentFeatureCard
                            key={index}
                            item={item}
                            duration={current}
                            profileId={establishmentProfile?.id}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}

                {ordered?.length > 0 && features?.length > 0 && (
                  <div className={"w-full  bg-gray-100 h-[1px] mt-10 mb-10"} />
                )}
                {features?.length > 0 && (
                  <div
                    className={
                      "flex flex-col gap-y-4 items-start justify-start w-full feature-cards-section"
                    }
                  >
                    <CardsGroupTitle title={"الخدمات الإضافية"} />
                    <div
                      className={
                        "grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4 gap-8 h-full w-full feature-card-wrapper"
                      }
                    >
                      {features?.map?.((item, index) => {
                        return (
                          <AssessmentFeatureCard
                            key={index}
                            item={item}
                            duration={current}
                            profileId={establishmentProfile?.id}
                            history={props.history}
                            trackType={"basic"}
                            isCreateInspection={() => {
                              setInspectionTriggered(!isInspectionTriggered);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <PopUp
          isOpen={isCancelInspection}
          title="تأكيد إلغاء التفتيش"
          body="يرجى العلم أنه سيتم إلغاء طلب المنشأة للتفتيش. هل أنت متأكد من قيامك بذلك؟"
          onClickAction={() => {
            onCancelInspection();
            onCancelInspectionPopUp(false);
          }}
          actionButtonText="تأكيد"
          onClickCancel={() => onCancelInspectionPopUp(false)}
          cancelButtonText="إلغاء"
        />
      </PageWrapper>
    );
  }
};
BasicAssessment.propTypes = {};

export default BasicAssessment;
