import request from "../common/request";

export const jiraHelpWidget =
  (name, email, type, description, attachments, phoneNumber) => () => {
    const formData = new FormData();

    // Append each attachment correctly
    attachments.forEach((attachment) => {
      formData.append("attachments", attachment, attachment.name);
    });

    // Append other form fields
    formData.append("name", name);
    formData.append("email", email);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("phone_number", phoneNumber);
    formData.append("audience", "ESTABLISHMENT");

    return request(
      "/jira/ticket/create",
      "POST",
      formData,
      {
        upload: true,
      },
      false
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.issueKey;
      })
      .catch((error) => {
        throw error;
      });
  };
