import React from "react";
import PropTypes from "prop-types";
import "./helpWidget.scss";

import Button from "../../../components/Button/Button";

import Draggable from "react-draggable";
import * as jiraActions from "../../../actions/jiraWidgetActions";
import { connect } from "react-redux";
import "./helpWidget.scss";

export class HelpWidget extends React.Component {
  constructor(props) {
    super(props);

    this.nodeRef = React.createRef(null);
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    const { history } = this.props;
    if (history.location.pathname == "/contactUs") {
      return;
    } else history.push("/contactUs");
  };

  render() {
    return (
      <div>
        <Draggable cancel=".btn" nodeRef={this.nodeRef}>
          <div className="help-widget-wrapper" ref={this.nodeRef}>
            {window.screen.width > 1200 ? (
              <Button
                text="تواصل معنا"
                onClick={this.toggle}
                customStyle="help-widget-button"
                id="help-button"
                customTextSize="h7"
                icon="img/ContactUsiconWhite.svg"
              />
            ) : (
              <button
                onClick={this.toggle}
                id="help-button"
                className="help-widget-button-mobile btn"
              >
                <img
                  src={"img/ContactUsiconWhite.svg"}
                  className={`button-icon`}
                  alt="button icon"
                  style={{ width: "30px" }}
                />
              </button>
            )}
          </div>
        </Draggable>
      </div>
    );
  }
}
HelpWidget.propTypes = { jiraHelpWidget: PropTypes.func };
HelpWidget.defaultProps = {};

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({
  jiraHelpWidget: (
    name,
    email,
    requestType,
    description,
    attachments,
    phoneNumber
  ) =>
    dispatch(
      jiraActions.jiraHelpWidget(
        name,
        email,
        requestType,
        description,
        attachments,
        phoneNumber
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpWidget);
