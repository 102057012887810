import React, { useState, useEffect } from "react";
import "../public/tailwind-output.css";
import { createRoot } from "react-dom/client";

import { HashRouter } from "react-router-dom";
// import { setup } from "../src/components/common/Config";
import "@babel/polyfill";

import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "../scss/style.scss";
//custom style
import "./styles/styles.css";
import "./styles/mobile.css";
import "./styles/audit.min.css";

// Containers
// import "../public/tailwind-output.css";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/custom.css";

import App from "./App";
import Maintenance from "./Maintenance";
import { ThemeProvider, createTheme } from "@mui/material/styles";

/*import InitSentry from "../../sentry";
InitSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN_ESTABLISHMENT,
  api_server: process.env.REACT_APP_API_URI,
});
*/
const history = createBrowserHistory();
const store = configureStore();
const theme = createTheme();

const MaintenanceWrapper = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const maintenanceStart = new Date("2024-10-18T13:00:00");
    const maintenanceEnd = new Date("2024-10-18T15:00:00");

    if (currentDate >= maintenanceStart && currentDate <= maintenanceEnd) {
      setIsMaintenance(true);
    } else {
      setIsMaintenance(false);
    }
  }, []);

  return (
    <HashRouter history={history}>
      <ThemeProvider theme={theme}>
        {isMaintenance ? <Maintenance /> : <App />}
      </ThemeProvider>
    </HashRouter>
  );
};

const app = (
  <Provider store={store}>
    <MaintenanceWrapper />
  </Provider>
);

// setup();
const container = document.getElementById("root");

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(app);
