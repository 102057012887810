import React, { useMemo } from "react";
import { useTable } from "react-table";
// import { Text } from "../../../../../Pages/AssessmentFeature/lib/components/typography";
import "./table.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";

export const Tables = ({ type, headerTitle, tableRows, headerClss }) => {
  const data = useMemo(() => tableRows, [tableRows]);
  const columns = useMemo(() => headerTitle, [headerTitle]);
  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="min-w-full">
      <table
        {...getTableProps()}
        // className={`${
        //   type === "primary"
        //     ? "min-w-full overflow-hidden rtl border-separate border-spacing-y-[15px] 	text-right"
        //     : " divide-gray-300 divide-y min-w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg text-right"
        // }`}
        style={{
          direction: "rtl",
          width: "100%",
          borderCollapse: "separate",
          borderSpacing: 0,
          overflow: "hidden",
        }}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      //   className={headerClss}
                      style={{
                        borderTopRightRadius: index === 0 ? "10px" : 0,
                        borderTopLeftRadius:
                          index === headerGroup.headers?.length - 1
                            ? "10px"
                            : 0,

                        backgroundColor: "#148287",
                      }}
                    >
                      {typeof column.Header == "string" ? (
                        <Text
                          Tag={"div"}
                          textType="bold"
                          fontSize={"font-16"}
                          className={"table-header"}
                          // size={type === "primary" ? "base" : "xl"}
                          // as={"h3"}
                        >
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </Text>
                      ) : (
                        column.render("Header")
                      )}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody
          {...getTableBodyProps()}
          //   className={`${type === "secondary" ? "divide-y" : ""}`}
        >
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, i) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          //   className={`${
                          //     type === "primary"
                          //       ? "bg-white p-[25px] first:pr-[50px]  h-[78px] first:rounded-r-[10px] last:rounded-l-[10px]"
                          //       : "bg-white px-[25px] py-[18px] first:pr-[60px] h-[60px] "
                          //   }`}
                          style={{
                            borderRight: i === 0 ? "1px solid #DFDFDF" : "none",
                            borderLeft:
                              i === row?.cells?.length - 1
                                ? "1px solid #DFDFDF"
                                : "none",
                            borderBottom: "1px solid #DFDFDF",

                            borderBottomRightRadius:
                              i === 0 && index === rows?.length - 1
                                ? "10px"
                                : 0,
                            borderBottomLeftRadius:
                              i === row?.cells?.length - 1 &&
                              index === rows?.length - 1
                                ? "10px"
                                : 0,
                          }}
                        >
                          {typeof cell.value == "string" ||
                          cell.value == undefined ? (
                            <Text
                              Tag={"div"}
                              textType={
                                index === rows?.length - 1 ? "bold" : "roman"
                              }
                              fontSize={"font-20"}
                              mobileFontSize={"font-16-mobile"}
                              className={"table-rows"}
                              // size={type === "primary" ? "lg" : "xl"}
                              style={{
                                color: "#0E2A37",
                                // padding: "18px 55px",
                                // margin: "0",
                              }}
                            >
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </Text>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};
Tables.defaultsProps = {
  type: "primary",

  headerTitle: [
    {
      Header: "عمود ١",
      accessor: "col1", // accessor is the "key" in the data
    },
    {
      Header: "عمود ٢",
      accessor: "col2",
    },
  ],
  tableRows: [
    {
      col1: " صف ١ عمود ١",
      col2: " صف ١ عمود ١",
    },
    {
      col1: " صف ٢ عمود ١",
      col2: " صف ٢ عمود ١",
    },
    {
      col1: " صف ٣ عمود ١",
      col2: "صف ٣ عمود ٢",
    },
  ],
};
