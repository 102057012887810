import React, { useEffect, useState } from "react";
import { AttachmentIcon } from "../../../lib/assets/icons/icons/index";
import { AttachmentUploader } from "./AttachmentUploader";
import { usePeriod } from "../../self-assessment-form-context";
import request from "../../../../../../common/request";
import { useSelector } from "react-redux";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../../common/constants/assessmentStatuses";
import { Backdrop } from "@mui/material";
import Loader from "../../../../../../components/Loader/Loader";
import "./attachments.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";

export const AttachmentsSection = ({
  attachments,
  step,
  refreshCaliber,
  errors,
  setErrors,
}) => {
  const [files, setFiles] = React.useState([]);
  const period = usePeriod();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );
  const [loading, setLoading] = useState(false);

  const uploadFile = async (file, index, attachment) => {
    try {
      setLoading(true);
      // Your existing code for file upload...
      const periodId = period.id;
      const assessmentVersion = period.assessment_version;
      const assessmentId = period.assessment.id;
      const data = new FormData();
      data.append("attachment", file);
      data.append("id", attachment.id);
      data.append("mandatory", attachment.mandatory);
      data.append("period_id", periodId);
      data.append("assessment_version", assessmentVersion);
      data.append("attachment_name", attachment.attachment_name);
      data.append("assessment_id", assessmentId);
      data.append("type", file.type);
      data.append("name", file.name);
      data.append("assessment_caliber_id", step?.caliber_id);

      const isReturned =
        (period && period.audit_status === RE_SUBMIT_STATUS) ||
        period.audit_status === RE_SUBMIT_STATUS_API_VALUE;
      const res = await request(
        `/v1/establishments/${establishmentProfileId}/${
          isReturned ? "returned_assessment" : "assessments"
        }/attachment`,
        "POST",
        data,
        { upload: true }
      ).then((res) => res.json());

      const updatedAttachments = [...attachments];
      updatedAttachments[index] = res?.attachment;
      refreshCaliber();
    } finally {
      setLoading(false);
    }
  };

  const removeRemoteFile = async (attachment) => {
    // console.log("attachment-to-remove", attachment);
    try {
      setLoading(true);
      const isReturned =
        (period && period.audit_status === RE_SUBMIT_STATUS) ||
        period.audit_status === RE_SUBMIT_STATUS_API_VALUE;
      const url = `/v1/establishments/${establishmentProfileId}/${
        isReturned ? "returned_assessment" : "assessments"
      }/attachment`;
      const res = await request(url, "DELETE", {
        submission_attachment_id: attachment.submission_attachment_id.UUID,
      })?.then((res) => res.json());
      // console.log("res-remove-attachment", res);
      refreshCaliber();
    } finally {
      setLoading(false);
    }
  };

  const handleGetAndUploadFile = async ({ file, index }) => {
    try {
      await uploadFile(file, index, attachments[index]);
      const newFiles = [...files];
      newFiles[index] = file;
      setFiles(newFiles);
    } catch (error) {
      console.log("handleGetAndUploadFile error : ", error);
    }
  };

  const handleRemoveFileByIndex = async (index) => {
    try {
      await removeRemoteFile(attachments[index]);
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {
    if (files?.length === 0 && attachments?.length > 0) {
      const newFiles = attachments?.filter(
        (att) => att?.submission_attachment_id.Valid === true
      );
      setFiles(newFiles);
    }
  }, [attachments]);

  // console.log("attachments", attachments);
  // console.log("files", files);

  return (
    <div>
      <div className="flex items-center gap-x-2  attachments-title">
        <AttachmentIcon className="w-4 h-4" />
        <Text
          Tag={"div"}
          textType="bold"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#14415A" }}
          className="mb-0"
        >
          {"المرفقات"}
        </Text>
      </div>

      <div className="flex attachments-wrapper">
        {attachments?.map?.((attachment, idx) => {
          const isError = errors?.attachments?.[idx] === true;
          return (
            <AttachmentUploader
              key={idx}
              isError={isError}
              file={files[idx]}
              removeFile={() => handleRemoveFileByIndex(idx)}
              attachment={attachment}
              name={attachment?.attachment_name}
              isMandatory={attachment?.mandatory}
              index={idx}
              getFile={handleGetAndUploadFile}
              setErrors={setErrors}
              errors={errors}
              acceptedTypes="application/pdf"
            />
          );
        })}
      </div>

      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <Loader />
        </Backdrop>
      )}
    </div>
  );
};
