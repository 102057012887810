import * as types from "./actionTypes";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";
import { getUserInfoSuccess } from "../../src/actions/authActions";
import {
  loadEstablishmentProfile,
  loadEstablishments,
} from "./establishmentProfileActions";
import { loadEstablishmentAciveAssessment } from "./establishmentAssessmentActions";
import pkceChallenge from "pkce-challenge";

export function logInSuccess(token) {
  return { type: types.LOG_IN_SUCCESS, token };
}

export function logOutSuccess(token) {
  return { type: types.LOG_OUT_SUCCESS, token };
}

export function loadUserInfoSuccess(user) {
  return { type: types.LOAD_USERINFO_SUCCESS, user };
}

export function logIn(user, history) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/login", "POST", user)
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res.json();
      })
      .then((token) => {
        dispatch(logInSuccess(token));
        dispatch(endAjaxCall());
        history.push("/establishmentProfile");
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function register(establishment, history) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/register", "POST", {
      establishment: establishment,
    })
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(
          logIn(
            {
              email: establishment.user.email,
              password: establishment.user.password,
            },
            history
          )
        );
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function resetPassword(user) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/reset", "POST", user)
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function confirm(user, token) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/" + token, "POST", user)
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getUserInfo(isLoadEstablishments = true) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request("/users/profile")
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadUserInfoSuccess(res.user ? res.user : {}));
        if (isLoadEstablishments) dispatch(loadEstablishments());
        dispatch(endAjaxCall());
        return res.user;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function logOut(token) {
  const refreshToken = sessionStorage.getItem(
    "mlsd_establishment_refresh_token"
  );
  let options;
  options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    // body: {
    //   client_id: window.env.CLIENT_ID,
    //   refresh_token: refreshToken,
    // },
  };

  return (dispatch) => {
    dispatch(beginAjaxCall());
    return fetch(
      `${process.env.REACT_APP_HRSD_SSO_URL}/realms/${process.env.REACT_APP_HRSD_SSO_REALM}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_HRSD_SSO_CLIENT_ID}&refresh_token=${refreshToken}`,
      options
    )
      .then((token) => {
        dispatch(logOutSuccess(token));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loggedIn() {
  return !!window.kc;
}

export function chooseEstablishment(establishment) {
  return function (dispatch) {
    return dispatch(loadEstablishmentProfile(establishment));
  };
}
export function loadDurations(establishmentId) {
  return function (dispatch) {
    return dispatch(loadEstablishmentAciveAssessment(establishmentId));
  };
}

export const confirmUserInfo = (establishmentId) => (dispatch) => {
  dispatch(beginAjaxCall());

  return request(`/users/information_approve/${establishmentId}`, "PUT")
    .then((res) => res.json())
    .then((result) => {
      dispatch({ type: types.CONFIRM_USER_INFO_SUCCESS });
      dispatch(endAjaxCall());
      return result;
    })
    .catch((e) => {
      dispatch(ajaxCallError(e));
      dispatch(endAjaxCall());
    });
  // .finally(() => {
  //   dispatch(endAjaxCall());
  // })
};

// generate code challange
export async function generateCodeChallenge() {
  var challenge = await pkceChallenge(128);
  sessionStorage.setItem("code_verifier", challenge.code_verifier);
  return challenge;
}

export async function buildLoginUrl() {
  console.log("start buildLoginUrl");
  // Base URL of the SSO authorization endpoint
  const authorizationEndpoint = process.env.REACT_APP_QIWA_SSO_ESTABLISHMENT_URL;
  // Client ID obtained during application registration
  const clientId = process.env.REACT_APP_QIWA_SSO_ESTABLISHMENT_CLIENT_ID;
  // Redirect URI where the SSO will redirect after authorization
  let redirectUri = encodeURIComponent(
    process.env.REACT_APP_API_URI + "/qiwa/redirect"
  );
  // Response type required for the authorization flow (code for authorization code flow)
  const responseType = "code";
  // Scopes required for accessing resources
  const scopes = process.env.REACT_APP_QIWA_SSO_ESTABLISHMENT_SCOPES;
  // generate state and store it in the session
  const state = Math.random().toString(36).slice(2);
  sessionStorage.setItem("state", state);
  // Code challenge derived from the code verifier

  // Generate code challenge and code verifier
  const codeChallengeObject = await generateCodeChallenge();
  const codeChallenge = codeChallengeObject.code_challenge;
  const codeVerifier = codeChallengeObject.code_verifier;
  // Construct the authorization link
  const authorizationLink = `${authorizationEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&scope=${scopes}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  // send the state and code verifer to backend
  const code = codeVerifier;
  postCodeAndState(code, state);
  console.log("buildLoginUrl.authorizationLink", authorizationLink);
  return authorizationLink;
}

export const postCodeAndState = async (code, state) => {
  try {
    const response = await request(
      `/qiwa/code`,
      "POST",
      {
        code_verifier: code,
        state: state,
      },
      {},
      false
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const requestToken = async (code, state) => {
  try {
    const response = await request(
      `/qiwa/redirect`,
      "POST",
      {
        code: code,
        state: state,
      },
      {},
      false
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};