import React from "react";
import PropTypes from "prop-types";

import {
  STATUS_CHECKOUT,
  STATUS_SUBMITTED,
  STATUS_READY,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUSES,
  STATUS_REGISTER,
} from "../../common/constants/trainingStatuses";
import "./establishmentTrainingCard.css";
import Button from "../Button/Button";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

export class EstablishmentTrainingCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTimeUp: false,
      isCancel: false,
    };

    this.isTimeUp = this.isTimeUp.bind(this);
    this.isCancel = this.isCancel.bind(this);
  }

  isTimeUp() {
    this.setState({ isTimeUp: !this.state.isTimeUp });
  }

  isCancel() {
    this.setState({ isCancel: !this.state.isCancel });
  }

  render() {
    const {
      training,
      redirectToEditTraining,
      showTrainingDetails,
      trainingIndex,
    } = this.props;

    const isPayButtonVisible =
      training.status === STATUS_CHECKOUT &&
      training.payment_resp_checkout_url !== "";
    return (
      <div className="establishment-training-card">
        <div
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <div style={{ maxWidth: "244px", flex: 1 }}>
                {this.props.isAvailableCourses ? (
                  <span>
                    <Text
                      Tag="div"
                      textType="h8"
                      isBold
                      className="establishment-training-card-training-title"
                    >
                      {training.name}
                    </Text>
                    <Text
                      Tag="div"
                      textType="h9"
                      className="establishment-training-card-training-description"
                    >
                      {training.description}
                    </Text>

                    <div className="flex items-center gap-x-1 ">
                      <img src="img/clock-2.svg" style={{ height: "25px" }} />
                      <Text
                        Tag="span"
                        textType="h10"
                        className="label-status establishment-training-card-details mt-0"
                      >
                        {training.total_hours} ساعة
                      </Text>
                    </div>
                    <div className="mt-4 flex items-center gap-x-1 ">
                      <img
                        src="img/money_bill.svg"
                        style={{ height: "19px", width: "29px" }}
                      />
                      <Text
                        Tag="span"
                        textType="h10"
                        className="label-status establishment-training-card-details mt-0"
                      >
                        {Number(
                          Math.round(
                            training.amount +
                              training.amount * training.vat +
                              "e" +
                              2
                          ) +
                            "e-" +
                            2
                        )}{" "}
                        <Text fontSize={"font-22"} textType="roman" Tag="span">
                          $
                        </Text>
                      </Text>
                    </div>
                  </span>
                ) : (
                  <span>
                    <Text
                      Tag="div"
                      textType="h7"
                      isBold
                      className="establishment-training-card-training-title"
                    >
                      {training.subject_name}
                    </Text>

                    <Text
                      Tag="div"
                      textType="h8"
                      className="establishment-training-card-training-description"
                    >
                      {training.subject_desc}
                    </Text>
                    <div className="flex items-center gap-x-1 ">
                      <img src="img/clock-1.svg" style={{ height: "26px" }} />
                      <Text
                        Tag="span"
                        textType="h10"
                        className="label-status establishment-training-card-details "
                      >
                        الحالة: {STATUSES[training.status]}
                      </Text>
                    </div>
                    {training.payment_resp_bill_number &&
                      training.status === STATUS_CHECKOUT && (
                        <div className="flex items-center gap-x-1 ">
                          <img
                            src="img/ic-invoice.svg"
                            style={{ height: "26px" }}
                          />
                          <Text
                            Tag="span"
                            textType="h10"
                            className="label-status establishment-training-card-details "
                          >
                            {training.payment_resp_bill_number}
                          </Text>
                        </div>
                      )}
                    {(training.status === STATUS_READY ||
                      training.status === STATUS_IN_PROGRESS ||
                      training.status === STATUS_COMPLETE) && (
                      <div className="flex items-center gap-x-1 ">
                        <img src="img/user.svg" style={{ height: "22px" }} />
                        {/* <span className="label-status establishment-training-card-details "></span> */}
                        {training.trainer_first_name ? (
                          <Text
                            Tag="span"
                            textType="h10"
                            className="label-status establishment-training-card-details "
                          >
                            {/* المدرب:{" "} */}
                            {`${training.trainer_first_name} ${training.trainer_last_name}`}
                          </Text>
                        ) : (
                          <Text
                            Tag="span"
                            textType="h10"
                            className="label-status establishment-training-card-details "
                          >
                            لم يتم تحديد المدرب حتى الآن
                          </Text>
                        )}
                      </div>
                    )}
                    {training.status === STATUS_SUBMITTED && (
                      <div className="flex items-center gap-x-1 ">
                        <img
                          src="img/money_bill.svg"
                          style={{ height: "19px" }}
                        />
                        <Text
                          Tag="span"
                          textType="h10"
                          className="label-status establishment-training-card-details "
                        >
                          {Number(
                            Math.round(
                              training.subject_amount +
                                training.subject_amount * training.subject_vat +
                                "e" +
                                2
                            ) +
                              "e-" +
                              2
                          )}{" "}
                          <Text
                            fontSize={"font-22"}
                            textType="roman"
                            Tag="span"
                          >
                            $
                          </Text>
                        </Text>
                      </div>
                    )}
                  </span>
                )}
              </div>
            </div>
            {this.props.isAvailableCourses ? (
              <div className="establishment-training-card-buttons-wrapper">
                <div>
                  <Button
                    primary
                    onClick={() => {
                      showTrainingDetails(training.id);
                    }}
                    text="اعرف المزيد"
                    id={`training-${trainingIndex}-know-more-button`}
                    style={{ minWidth: "125px" }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Button
                    onClick={() => {
                      redirectToEditTraining(training.id, STATUS_REGISTER);
                    }}
                    text="سجل الآن"
                    id={`training-${trainingIndex}-register-button`}
                    style={{ minWidth: "125px" }}
                  />
                </div>
              </div>
            ) : (
              <div className="establishment-training-card-buttons-wrapper">
                <Button
                  style={{
                    minWidth: "118px",
                    flex: isPayButtonVisible ? 0 : 1,
                  }}
                  onClick={() => {
                    showTrainingDetails(training.id, training.status);
                  }}
                  text="استعراض"
                  primary={isPayButtonVisible}
                  id={`training-${trainingIndex}-view-button`}
                />
                {isPayButtonVisible && (
                  <div style={{ marginRight: "8px" }}>
                    <Button
                      onClick={() => {
                        const { training, onClickPayment } = this.props;
                        onClickPayment(training);
                      }}
                      text="ادفع"
                      id={`training-${trainingIndex}-pay-button`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EstablishmentTrainingCard.propTypes = {
  training: PropTypes.object,
  redirectToEditTraining: PropTypes.func,
  showTrainingDetails: PropTypes.func,
  redirectToSelfAssessmentPayment: PropTypes.func,
  checkout: PropTypes.func,
  onClickPayment: PropTypes.func,
  isAvailableCourses: PropTypes.bool,
  trainingIndex: PropTypes.number,
};
EstablishmentTrainingCard.defaultProps = {
  isAvailableCourses: false,
};

export default EstablishmentTrainingCard;
