import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./summary.scss";
import Button from "../../../components/Button/Button";
import {
  getSummaryButtonText,
  getSummaryDescription,
  getSummaryImage,
} from "./SummaryText";

const Summary = ({
  isStartFreeAssessment,
  isReturnedAssessment,
  isPayForAssessment,
  isBlocked,
  isUnderAuditing,
  isCompleted,
  isFastTrackAssessmentPayment,
  isFastTrackAssessmentAvailable,
  isGoToFastTrackAssessment,
  isReassessment,
  isReAssessmentSubmitted,
  isFastTrackAssessment,
  isContinueAssessment,
  isCompletedAndCertified,
  isCompletedAndNotCertified,
  onViewCertificate,
  onCancelInspection,
  onClickButton = () => {},
  isNewInspection = false,
}) => {
  return (
    <div className="summary-box">
      <div>
        <Text Tag="div" textType="h6" isBold className="summary-text-style">
          {getSummaryDescription(
            isStartFreeAssessment,
            isReturnedAssessment,
            isPayForAssessment,
            isBlocked,
            isUnderAuditing,
            isCompleted,
            isFastTrackAssessmentAvailable,
            isGoToFastTrackAssessment,
            isReAssessmentSubmitted,
            isFastTrackAssessment,
            isReassessment,
            isCompletedAndCertified,
            isCompletedAndNotCertified
          )}
        </Text>
        <span className="fast-track-assessment-buttons-wrapper ">
          <Button
            text={getSummaryButtonText(
              isStartFreeAssessment,
              isReturnedAssessment,
              isPayForAssessment,
              isBlocked,
              isUnderAuditing,
              isCompleted,
              isFastTrackAssessmentAvailable,
              isGoToFastTrackAssessment,
              isReAssessmentSubmitted,
              isContinueAssessment,
              isReassessment,
              isCompletedAndCertified,
              isCompletedAndNotCertified
            )}
            onClick={onClickButton}
            customTextSize={"h7"}
            disabled={isFastTrackAssessmentPayment === false}
            customStyle={`${
              isReassessment
                ? "summary-button-style custom-button-reassessment"
                : "summary-button-style"
            }`}
          />
          {isCompletedAndCertified && (
            <Button
              text={"عرض الشهادة"}
              onClick={onViewCertificate}
              customTextSize={"h7"}
              disabled={isFastTrackAssessmentPayment === false}
              customStyle={`${
                isReassessment
                  ? "summary-button-style custom-button-reassessment fast-track-certificate-button"
                  : "summary-button-style fast-track-certificate-button"
              }`}
              primary
            />
          )}
          {isNewInspection && (
            <Button
              text={"إلغاء التفتيش"}
              onClick={onCancelInspection}
              customTextSize={"h7"}
              customStyle={`${"summary-button-style fast-track-certificate-button"}`}
              primary
            />
          )}
        </span>
      </div>
      <div className="summary-image">
        <img
          src={getSummaryImage(
            isStartFreeAssessment,
            isReturnedAssessment,
            isPayForAssessment,
            isBlocked,
            isUnderAuditing,
            isCompleted,
            isFastTrackAssessment,
            isFastTrackAssessmentAvailable,
            isGoToFastTrackAssessment,
            isReassessment,
            isReAssessmentSubmitted,
            isCompletedAndCertified,
            isCompletedAndNotCertified
          )}
          alt="laptop"
        />
      </div>
    </div>
  );
};
Summary.propTypes = {};

export default Summary;
