import React from "react";
import Content from "../../containers/Content/Content";

import "../home/homepage.css";
import "./faqPage.css";
import { faq } from "../home/static-data";
import CollapseItem from "../home/CollapseItem";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
class FaQPage extends React.Component {
  constructor(props, context) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderSectionTitle(title) {
    return (
      <Text Tag="div" textType="h6" isBold className="faq-section-title ">
        {title}
      </Text>
    );
  }

  render() {
    return (
      <Content history={this.props.history}>
        <div className="container-fluid-size ">
          <div className="justify-content-center faq-page-wrap">
            <div className="animated fadeIn main-page">
              <TitleWithSubTitle
                subtitle={faq.title}
                customStyle="page-title"
              />

              <div className="mb-0">
                <div className="faq-card-body ">
                  {this.renderSectionTitle("عن المنصة")}

                  <CollapseItem
                    title={faq.cards[0].title}
                    description={faq.cards[0].description}
                    id={`faq-1`}
                  />
                  <CollapseItem
                    title={faq.cards[1].title}
                    description={faq.cards[1].description}
                    id={`faq-2`}
                  />

                  {this.renderSectionTitle("التقييم الذاتي")}
                  {faq.cards.map(
                    (item, i) =>
                      i >= 2 &&
                      i <= 11 && (
                        <CollapseItem
                          key={i}
                          title={item.title}
                          description={item.description}
                          id={`faq-${i + 1}`}
                        />
                      )
                  )}

                  {this.renderSectionTitle("التسجيل")}

                  {/* <CollapseItem
                    title={faq.cards[12].title}
                    description={faq.cards[12].description}
                    id={`faq-13`}
                  /> */}
                  <CollapseItem
                    title={faq.cards[12].title}
                    description={faq.cards[12].description}
                    id={`faq-14`}
                  />

                  {this.renderSectionTitle("وسائل التواصل")}
                  <CollapseItem
                    title={faq.cards[13].title}
                    description={faq.cards[13].description}
                    id={`faq-15`}
                  />
                  <CollapseItem
                    title={faq.cards[14].title}
                    description={faq.cards[14].description}
                    id={`faq-16`}
                  />
                  {this.renderSectionTitle("التدقيق")}
                  <CollapseItem
                    title={faq.cards[15].title}
                    description={faq.cards[15].description}
                    id={`faq-17`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

export default FaQPage;
