import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  STATUSES,
  STATUS_NOT_PAID,
  STATUS_PAID,
  STATUS_OUT_DATE,
  EXPORT_ZATCA,
  PENDDING_ZATCA,
} from "../../../common/constants/billStatuses";
import { STATUSES as ASSESSMENT_STATUSES } from "../../../common/constants/paymentStatuses";
import * as profileActions from "../../../actions/establishmentProfileActions";
import toastr from "toastr";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import Pagination from "./Pagination";
import Button from "../../../components/Button/Button";
import InvoicePdf from "./InvoicePdf";
import "./billsList.scss";
import "./print.css";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import { getZatcaBillPdf } from "../../../actions/billInfo";
import Lottie from "react-lottie";
import * as animationLoader from "../../../../public/animation/loading.json";
import { SdadModal } from "./SdadModal";
import MobileBillsList from "./MobileBillsList";

export class BillsList extends React.Component {
  constructor() {
    super();

    this.state = {
      start: 0,
      limit: 5,
      currentPage: 1,
      invoiceToPrint: {},
      isSdadModalOpen: false,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.handleOpenSdadModal = this.handleOpenSdadModal.bind(this);
    this.getStatusColor = this.getStatusColor.bind(this);
    this.loadAllEstablishmentBills = this.loadAllEstablishmentBills.bind(this);
    this.openFile = this.openFile.bind(this);
    this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);
  }
  componentDidMount() {
    this.loadAllEstablishmentBills();
  }

  componentDidUpdate(prevProps, prevState) {
    const { start, currentPage } = this.state;
    const { start: prevStart } = prevState;
    const { currentPage: prevCurrentPage } = prevState;
    if (start !== prevStart && prevCurrentPage !== currentPage) {
      this.loadAllEstablishmentBills();
    }
  }
  loadAllEstablishmentBills() {
    const { start, limit } = this.state;
    const { establishmentProfile, loadAllEstablishmentBills } = this.props;
    loadAllEstablishmentBills(establishmentProfile.id, start, limit)
      .then(() => {})
      .catch((error) => {
        toastr.error("حدث خطأ ما");
      });
  }
  onPageChange(selectedItem) {
    const { limit } = this.state;
    const selectedPage = selectedItem - 1;
    this.setState({
      start: selectedPage * limit,
      currentPage: selectedItem,
    });
  }
  isPaid(bill) {
    return bill.payment_status === STATUS_PAID;
  }
  getStatusColor(status) {
    switch (status) {
      case STATUS_NOT_PAID:
        return "purple";
      case STATUS_PAID:
        return "green";
      case STATUS_OUT_DATE:
        return "gray";
    }
  }
  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString?.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  openFile(byte) {
    var blob = new Blob([byte], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  onInvoiceClick = async (bill) => {
    const { establishmentProfile } = this.props;

    await this.setState({ invoiceToPrint: bill });
    this.isPaid(bill)
      ? bill.zatca_status === EXPORT_ZATCA
        ? this.props
            .getZatcaBillPdf(establishmentProfile.id, bill.zatca_bill_number)
            .then((file) => {
              const byteArray = this.base64ToArrayBuffer(
                file.invoice_receipt.bytes
              );
              this.openFile(byteArray);
            })
        : window.print()
      : window.open(bill.payment_resp_checkout_URL, "_blank");
  };

  handleOpenSdadModal(currentBill) {
    this.setState({ isSdadModalOpen: true, currentBill });
  }
  render() {
    const { limit, currentPage, invoiceToPrint } = this.state;
    const {
      billsCount,
      establishmentProfile,
      establishmentAllBills,
      isLoading,
    } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationLoader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <span className="billsList">
        <span className="page-wrapper doNotPrint">
          <PageWrapper>
            {isLoading ? (
              <div className="loader-div">
                <Lottie options={defaultOptions} height={160} width={160} />
                <p className="assessment-submission-loading-text">
                  يرجى الانتظار..
                </p>
              </div>
            ) : (
              <div className="billsList ">
                <div className="bills-list-header">
                  <Text
                    Tag="div"
                    textType="bold"
                    fontSize="font-24"
                    mobileFontSize="font-18-mobile"
                    className="bills-list-header__text"
                  >
                    الفواتير
                  </Text>
                  <div className="bills-list-header__info-message">
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-18"}
                      mobileFontSize="font-16-mobile"
                    >
                      يمكنك من خلال هذه الصفحة استعراض عمليات الدفع والفواتير.{" "}
                    </Text>
                    <Text
                      Tag="span"
                      textType="bold"
                      fontSize={"font-18"}
                      mobileFontSize="font-16-mobile"
                    >
                      يرجى العلم أنه في حال الدفع عن طريق سداد قد تتأخر عملية
                      تحديث حالة الدفع و إصدار الفاتورة.
                    </Text>
                  </div>
                </div>
                {billsCount === 0 ? (
                  <Text Tag="div" textType="p4">
                    لا يوجد فواتير
                  </Text>
                ) : (
                  <Fragment>
                    <div className="mobile-bills-list">
                      <MobileBillsList
                        bills={establishmentAllBills}
                        sdadInvoiceClick={(bill) =>
                          this.handleOpenSdadModal(bill)
                        }
                        invoiceClick={async (bill) =>
                          await this.onInvoiceClick(bill)
                        }
                        isPaid={(bill) => this.isPaid(bill)}
                        isSdadModalOpen={this.state.isSdadModalOpen}
                      />
                    </div>
                    <div className="self-assessment-list-content table-x-axis-scroll desktop-bills-list">
                      <div className="self-assessment-table">
                        <div className="self-assessment-table__header">
                          <div className="self-assessment-table__row">
                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--name"
                            >
                              اسم الفترة
                            </Text>

                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--name"
                            >
                              نوع الخدمة
                            </Text>
                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--number"
                            >
                              رقم الفاتورة
                            </Text>

                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--center self-assessment-table__item--date"
                            >
                              موعد انتهاء الدفع{" "}
                              <Tooltip
                                placement="left"
                                title={
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      padding: "2px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    فيه حال تم الدفع تكون الفاتورة ساريه
                                  </div>
                                }
                              >
                                <InfoOutlined fontSize="small" />
                              </Tooltip>
                            </Text>
                            {/* <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--center self-assessment-table__item--date"
                            >
                              رقم سداد
                            </Text> */}

                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--center self-assessment-table__item--date"
                            >
                              مبلغ الفاتورة
                            </Text>
                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item self-assessment-table__item--center self-assessment-table__item--number"
                            >
                              الحالة
                            </Text>
                            <Text
                              isBold
                              textType="h9"
                              className="self-assessment-table__item  self-assessment-table__item--center self-assessment-table__item--case"
                            >
                              الإجراءات
                            </Text>
                          </div>
                        </div>
                        <div className="self-assessment-table__body">
                          {establishmentAllBills.map((bill, index) => (
                            <div
                              className="self-assessment-table__row"
                              key={`${bill.id}-${index}`}
                            >
                              <Text
                                textType="p5"
                                className="self-assessment-table__item"
                              >
                                {bill.period_name}
                              </Text>

                              <Text
                                textType="p5"
                                className="self-assessment-table__item"
                              >
                                {ASSESSMENT_STATUSES[bill.service_type]}
                              </Text>

                              <Text
                                textType="p5"
                                className="self-assessment-table__item"
                              >
                                {bill.payment_resp_bill_number}
                              </Text>
                              <Text
                                textType="p5"
                                className="self-assessment-table__item self-assessment-table__item--center"
                              >
                                {`${moment(bill.due_date).format(
                                  "MMM DD YYYY h:mm a"
                                )}`}
                              </Text>
                              {/* <Text
                                textType="p5"
                                className="self-assessment-table__item self-assessment-table__item--center"
                              >
                                {bill.sadad_number}
                              </Text> */}
                              <Text
                                fontSize={"font-16"}
                                textType="roman"
                                className="self-assessment-table__item self-assessment-table__item--center"
                              >
                                {` ${bill.totalAmount} `}
                                <Text
                                  fontSize={"font-27"}
                                  textType="roman"
                                  Tag="span"
                                >
                                  $
                                </Text>
                              </Text>
                              <div className="self-assessment-table__item self-assessment-table__item--center">
                                <Text
                                  Tag="span"
                                  textType="p5"
                                  className={`self-assessment-table__item-status self-assessment-table__item-status--${this.getStatusColor(
                                    bill.payment_status
                                  )}`}
                                >
                                  {STATUSES[bill.payment_status]}
                                </Text>
                              </div>
                              <div className="self-assessment-table__item self-assessment-table__item--center">
                                {bill.payment_status ===
                                STATUS_OUT_DATE ? null : bill.zatca_status ===
                                    PENDDING_ZATCA && this.isPaid(bill) ? (
                                  <Text
                                    Tag="div"
                                    textType="h10"
                                    isBold
                                    className="pending-status"
                                  >
                                    جاري إصدار الفاتورة . .
                                  </Text>
                                ) : (
                                  <Fragment>
                                    {bill.payment_status === "Not-Paid" ? (
                                      <Button
                                        onClick={() =>
                                          this.handleOpenSdadModal(bill)
                                        }
                                        style={{ minWidth: "144px" }}
                                        isOpen={this.state.isSdadModalOpen}
                                        text="بيانات السداد"
                                      />
                                    ) : (
                                      <Button
                                        style={{ minWidth: "146px" }}
                                        isBold={false}
                                        onClick={async () =>
                                          await this.onInvoiceClick(bill)
                                        }
                                        iconHover={
                                          this.isPaid(bill)
                                            ? "img/downloadIconHover.svg"
                                            : ""
                                        }
                                        icon={
                                          this.isPaid(bill)
                                            ? "img/downloadIcon.svg"
                                            : null
                                        }
                                        text={
                                          this.isPaid(bill)
                                            ? "تحميل الفاتورة"
                                            : "ادفع الآن"
                                        }
                                        primary={this.isPaid(bill)}
                                        id={`bill-${index}-button`}
                                        disabled={!this.isPaid(bill)}
                                      />
                                    )}
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div id="react-paginate">
                      <Pagination
                        itemsCount={billsCount}
                        pagesLimit={limit}
                        currentPage={currentPage}
                        onPageChange={this.onPageChange}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </PageWrapper>
        </span>
        {invoiceToPrint && (
          <div className="toPrint">
            <InvoicePdf
              invoice={this.state.invoiceToPrint}
              establishmentName={establishmentProfile.establishment_name}
              establishmentAddress={establishmentProfile.google_address}
              establishmentTaxNumber={establishmentProfile.tax_number}
            />
          </div>
        )}

        <SdadModal
          isOpen={this.state.isSdadModalOpen}
          bill={this.state?.currentBill}
          handleClose={() =>
            this.setState({
              isSdadModalOpen: false,
              currentBill: undefined,
            })
          }
        />
      </span>
    );
  }
}

BillsList.defaultProps = {
  match: { params: {} },
};

BillsList.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  loadAllEstablishmentBills: PropTypes.func.isRequired,
  establishmentAllBills: PropTypes.array,
  billsCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  establishmentAllBills: state.payment.establishmentAllBills,
  billsCount: state.payment.billsCount,
  establishmentProfile: state.establishment.establishmentProfile,
  isLoading: state.billInfo.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getZatcaBillPdf: (establishmentId, billNumber) =>
    dispatch(getZatcaBillPdf(establishmentId, billNumber)),
  loadAllEstablishmentBills: (establishmentId, start, limit) =>
    dispatch(
      profileActions.loadAllEstablishmentBills(establishmentId, start, limit)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsList);
