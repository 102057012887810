import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class EmptyEstablishments extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h4 className="pt-3">عذرا</h4>
                <p className="text-muted float-right">
                  {" "}
                  أنت غير مفوض على أي منشأة حاليا
                </p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EmptyEstablishments;
