import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import "./dropZone.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

function formatBytes(
  bytes,
  decimals = 2,
  acceptedFileTypes = "image/jpeg, image/png, image/bmp, image/jpg, application/pdf",
  maxSize = 5242880
) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const DropZone = (props) => {
  const { acceptedFileTypes, DropZoneTitle, uploadAttachment, maxSize } = props;
  const [files, setFiles] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [isRejectedFileType, setIsRejectedFileType] = useState(false);
  const acceptedTypesArray = acceptedFileTypes?.replaceAll(" ", "").split(",");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: acceptedFileTypes,
    multiple: false,
    noClick: false,
    maxSize: maxSize,

    onDropRejected: (fileRejections) => {
      if (fileRejections[0].errors[0].code === "file-too-large") {
        setErrorText("حجم الملف يجب أن يكون 5 ميجا بايت أو أقل");
      } else if (fileRejections[0].errors[0].code === "file-invalid-type")
        setErrorText(`نوع الملف غير مسموح به `);
      setIsRejectedFileType(false);
    },

    onDragEnter: (event) => {
      const fileType = event.dataTransfer.items[0].type;
      setErrorText(null);
      if (acceptedTypesArray.includes(fileType)) {
        setIsRejectedFileType(false);
      } else {
        setIsRejectedFileType(true);
      }
    },
    onDragLeave: () => {
      setIsRejectedFileType(false);
    },
  });

  useEffect(() => {
    const acceptedFileItems = acceptedFiles.map((file, i) => (
      <li key={file.path} className="drop-zone-file-wrapper">
        {file.path} - {formatBytes(file.size)}{" "}
        <i
          className="drop-zone-delete-button-wrapper"
          onClick={() => remove(i)}
        >
          <img
            src={"img/trashCan.svg"}
            alt="button icon"
            className="drop-zone-delete-button"
          />
        </i>
      </li>
    ));
    setFiles(acceptedFileItems);
  }, [acceptedFiles]);

  useEffect(() => {
    if (files.length > 0) {
      acceptedFiles.map((attachment) => {
        uploadAttachment(attachment);
      });
    }
  }, [files]);

  const remove = (file) => {
    const newFiles = [...files]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    acceptedFiles.splice(acceptedFiles, 1); // remove the file from the array

    setFiles(newFiles);
  };
  const dragRejectedText = "نوع الملف غير مسموح به";
  return (
    <div className="container" style={{ padding: 0 }}>
      {files.length > 0 ? (
        <p>{files}</p>
      ) : (
        <div>
          <div
            {...getRootProps()}
            className={`drop-zone-wrapper ${
              isRejectedFileType ? "drop-zone-wrapper__error" : ""
            }`}
          >
            <input {...getInputProps()} />

            <Text Tag="p" textType={"h9"} className={`drop-zone-text `}>
              {isRejectedFileType ? dragRejectedText : DropZoneTitle}
            </Text>
          </div>
          {errorText && (
            <Text className="text-area__error-hint" Tag="span" textType="p6">
              {errorText}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

DropZone.propTypes = {
  acceptedFileTypes: PropTypes.string,
  DropZoneTitle: PropTypes.string,
  maxFiles: PropTypes.number,
  uploadAttachment: PropTypes.func,
  maxSize: PropTypes.number,
};

export default DropZone;
