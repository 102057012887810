import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import "./assessmentInstructions.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import { Link } from "react-router-dom";

import Button from "../../../components/Button/Button";
import { resetSAFormPeriod } from "../../../actions/SAformActions";
import { useDispatch } from "react-redux";
function AssessmentInstructions({ history, match }) {
  function redirectToEstablishmentAssessmentPage() {
    const {
      params: { id, assessment_version, isBasicAssessment, periodAssessmentId },
    } = match;

    history.push(
      `/establishmentAssessment/${id}/${assessment_version}/${isBasicAssessment}/${periodAssessmentId}`
    );
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSAFormPeriod());
  }, []);
  return (
    <PageWrapper withBreadcrumbs>
      <div className="assessment-instructions-card ">
        <Text
          isBold
          Tag="div"
          textType="h6"
          className="assessment-instructions-card__title"
        >
          قبل البدء بالتقييم يرجى اتباع التعليمات التالية :
        </Text>
        <div className="content ">
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                1
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              التأكد من مراجعة{" "}
              <Link
                to="/interactiveGuide"
                target="_blank"
                style={{ color: "#1b8386", textDecoration: "underline" }}
              >
                المعايير المتعلقة بالتقييم الذاتي
              </Link>{" "}
              قبل البدء.
            </Text>
          </div>
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                2
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              التأكد من حجم المنشاة ،في حال تغيير حجم المنشأة فالحجم المعتمد هو
              الذي سجل في بداية السنة الميلادية.
            </Text>
          </div>
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                3
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              التقييم الذاتي متطلب للإستفادة من خدمات التوسع في منصة قوى وذلك حسب القرار الوزاري رقم 188734.
            </Text>
          </div>
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                4
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              عند الإجابة على أسئلة التقييم ،يرجى التأكد من إرفاق الملفات
              المطلوبة.
            </Text>
          </div>
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                5
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              مراجعة نموذج التقييم الذاتي قبل الإرسال.
            </Text>
          </div>
          <div className="assessment-instructions-card__item">
            <div className="circle">
              <Text tag="div" textType="h5" isBold className="circle-text">
                6
              </Text>
            </div>
            <Text
              tag="div"
              textType="p3"
              className="assessment-instructions-card__text  assessment-instructions-card__text-wrapper "
            >
              يجب ان تكون جميع المرفقات باللغة العربية.
            </Text>
          </div>
        </div>
      </div>
      <div className="assessment-instructions-card__buttons-wrapper ">
        <Button
          primary
          onClick={() => {
            history.goBack();
          }}
          text="إلغاء"
          style={{
            width: "118px",
            height: "38px",
          }}
        />
        <div className="assessment-instructions-card__buttons-wrapper__start">
          <Button
            onClick={() => redirectToEstablishmentAssessmentPage()}
            text="بدء التقييم"
            style={{
              width: "118px",
              height: "38px",
            }}
          />
        </div>
      </div>
    </PageWrapper>
  );
}

AssessmentInstructions.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default AssessmentInstructions;
