import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Row } from "reactstrap";

import {
  STATUS_NOT_PAID,
  STATUS_OUT_DATE,
  STATUS_PAID,
  STATUSES,
} from "../../common/constants/billStatuses";

import moment from "moment";

//class not used
class EstablishmentBillCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collapsed !== nextProps.collapsed) {
      this.setState({ collapsed: nextProps.collapsed });
    }
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  componentWillMount(props) {
    const { isOpenByDefault } = this.props;

    if (isOpenByDefault) {
      this.setState({ collapsed: false });
    }
  }

  render() {
    const { bill, checkOut } = this.props;
    const { collapsed } = this.state;

    return (
      <Col>
        <Row style={{ padding: "30px" }}>
          <Col md={9}>
            <Row>
              <span className="label-caliberCategory d-flex justify-content-right service-green-title">{`${bill.payment_resp_bill_number}`}</span>
            </Row>
            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;تاريخ اصدار الفاتورة:{" "}
              </span>
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;
                {` ${moment(bill.created_at).format("YYYY-MM-DD hh:mm A")} `}
                &nbsp;
              </span>
            </Row>

            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;المبلغ الكلي للخدمة :{" "}
              </span>{" "}
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;{` ${bill.amount} ريال`}&nbsp;
              </span>
            </Row>
            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;القيمة المضافة :{" "}
              </span>
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;{` ${bill.amount * bill.vat} ريال`}&nbsp;
              </span>
            </Row>
            <Row style={{ paddingTop: "5px" }}>
              <img
                src={"img/asset_check.svg"}
                style={{ width: 20, height: 20 }}
              />
              <span className="d-flex justify-content-right">
                &nbsp;المبلغ الكلي :{" "}
              </span>
              <span className="label-caliberCategory d-flex justify-content-right">
                {" "}
                &nbsp;{` ${bill.totalAmount} ريال`}&nbsp;
              </span>
            </Row>
          </Col>
          <Col md={3}>
            <div
              className="bill-head-val"
              style={{ color: "red", float: "left" }}
            >
              {bill.bill_status == STATUS_NOT_PAID && (
                <span>
                  <i
                    style={{ color: "#7FB156" }}
                    className="fa fa-check-circle"
                  />
                  {"  "}
                  <span style={{ color: "#7FB156" }} color="success">
                    {STATUSES[bill.bill_status]}
                  </span>
                </span>
              )}
              {bill.bill_status == STATUS_PAID && (
                <span>
                  <i
                    style={{ color: "#7FB156" }}
                    className="fa fa-check-circle"
                  />
                  {"  "}
                  <span style={{ color: "#7FB156" }} color="success">
                    {STATUSES[bill.bill_status]}
                  </span>
                </span>
              )}
              {bill.bill_status == STATUS_OUT_DATE && (
                <span>
                  <i
                    style={{ color: "#EE5F5A" }}
                    className="fa fa-check-circle"
                  />
                  {"  "}
                  <span style={{ color: "#EE5F5A" }} color="success">
                    {STATUSES[bill.bill_status]}
                  </span>
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div
              className="horizontal-line"
              style={{ marginTop: 10, borderTop: "1px solid #679b27" }}
            ></div>
          </Col>
        </Row>

        <div
          className="report-head-val"
          style={{
            color: "red",
            paddingLeft: "20px",
            paddingRight: "20px",
            float: "left",
          }}
        >
          {bill.bill_status == STATUS_NOT_PAID && (
            <Button
              color="primary"
              className="btn-round"
              onClick={() => {
                checkOut(bill.payment_resp_checkout_URL);
              }}
            >
              ادفع
            </Button>
          )}
        </div>
      </Col>
    );
  }
}

EstablishmentBillCard.propTypes = {
  bill: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  checkOut: PropTypes.func,
};

export default EstablishmentBillCard;
