import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as beforeLoadActions from "../../actions/beforeLoadActions";
import { buildLoginUrl } from "../../actions/authActions";
import Keycloak from "keycloak-js";
import * as profileActions from "../../actions/establishmentProfileActions";
import Lottie from "react-lottie";
import * as animationLoader from "../../../public/animation/loading.json";

export class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (process.env.REACT_APP_SSO_ESTABLISHMENT_AUTHORIZATION_METHOD == "hrsd") {
      const kc = new Keycloak({
        url: process.env.REACT_APP_HRSD_SSO_URL,
        realm: process.env.REACT_APP_HRSD_SSO_REALM,
        clientId: process.env.REACT_APP_HRSD_SSO_CLIENT_ID,
        timeSkew: 300,
      });
      let userInfo;
      kc.init({
        onLoad: "login-required",
        checkLoginIframe: false,
        useNonce: false,
      })
        .then((authenticated) => {
          if (authenticated) {
            window.kc = kc;
            this.props.actions.logIn(kc.token);
            if (localStorage.getItem("selected")) {
              this.props.saveSelectedEstablishment(
                JSON.parse(localStorage.getItem("selected"))
              );
            }
            return this.props.actions
              .getUserProfile()
              .then((res) => (userInfo = res));
          }
        })
        .then((res) => {
          this.redirect(userInfo);
        });
    } else {
        console.log("not hrsd, process.env.REACT_APP_SSO_ESTABLISHMENT_AUTHORIZATION_METHOD = ", process.env.REACT_APP_SSO_ESTABLISHMENT_AUTHORIZATION_METHOD);
        buildLoginUrl().then((res) => {window.location.href = res;});
    }
  }

  redirect(userInfo) {
    const establishmentProfile = JSON.parse(localStorage.getItem("selected"));

    if (establishmentProfile) {
      const isProfileFirstLogin =
        establishmentProfile.id && !establishmentProfile.is_confirmed;
      if (
        isProfileFirstLogin ||
        (userInfo && userInfo.is_email_editable && userInfo.email === "") ||
        (userInfo && userInfo.is_email_editable && !userInfo.email)
      ) {
        this.props.history.replace("/establishmentProfile");
      } else {
        this.props.history.replace("/establishmentBasicDurationsPage");
      }
    } else {
      this.props.history.replace("/establishmentProfile");
    }
  }

  render() {
    return (
      <div>
        <div className="loading-screen">
          <div className="loader-div">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={160}
              width={160}
            />
            <p className="loader-text">يرجى الانتظار..</p>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  state: PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.object,
  router: PropTypes.object,
};

// Login.contextTypes = {
//   actions: PropTypes.object,
//   router: PropTypes.object,
// };

function mapStateToProps(state) {
  return {
    state: state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(beforeLoadActions, dispatch),
    saveSelectedEstablishment: (establishment) =>
      dispatch(profileActions.loadEstablishmentProfile(establishment)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);