import React, { forwardRef, useState } from "react";

import PropTypes from "prop-types";
import "./textInput.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const TextInput = forwardRef(
  (
    {
      type,
      value,
      onChange,
      errorText,
      name,
      maxLength,
      id,
      customWrapper,
      disabled = false,
      placeholder = "",
      onClick = () => {},
      wrapperStyle = {},
      inputStyle = {},
      inputWrapperStyle = {},
      onBlur = () => {},
      onFocus = () => {},
      icon = null,
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);

    return (
      <div
        className={`custom-text-input-wrapper ${customWrapper}`}
        style={wrapperStyle}
      >
        <div
          className={`custom-text-input ${
            focus && !errorText ? "focus-text-input" : ""
          } ${errorText ? "custom-text-input__error" : ""} ${
            disabled ? "disabled-text-input" : ""
          } `}
          onFocus={() => {
            setFocus(!focus);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocus(!focus);
            onBlur && onBlur();
          }}
          onClick={() => onClick()}
          style={inputWrapperStyle}
        >
          {icon && (
            <img src={icon} alt="icon" className="text-input-with-icon__icon" />
          )}
          <input
            type={type}
            value={value}
            onChange={onChange}
            className={`custom-text-input__input ${
              errorText ? "custom-text-input__input-error" : ""
            }${disabled ? "disabled-inner-text-input" : ""}`}
            disabled={disabled}
            placeholder={placeholder}
            style={inputStyle}
            ref={ref}
            name={name}
            maxLength={maxLength}
            autoComplete="off"
            id={id}
          />
        </div>
        {errorText && (
          <Text
            className="custom-text-input__error-hint"
            Tag="span"
            textType="roman"
            fontSize={"font-14"}
          >
            {errorText}
          </Text>
        )}
      </div>
    );
  }
);
TextInput.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  wrapperStyle: PropTypes.object,
  inputWrapperStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  maxLength: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default TextInput;
