import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./resultCard.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import { useSpring, animated, config } from "react-spring";
import { InView } from "react-intersection-observer";

function SkillProgress(props) {
  const [isInView, setIsInView] = React.useState(false);

  const onVisibilityChange = (inView, entry) => setIsInView(inView);

  const progressSpringStyleProps = useSpring({
    width: isInView ? `${props.percent >= 100 ? 100 : props.percent}%` : `0%`,
    config: config.molasses,
  });
  return (
    <InView as="div" onChange={onVisibilityChange}>
      <div className="result-card-progress-item">
        <Text
          Tag="div"
          fontSize={"font-18"}
          textType="roman"
          mobileFontSize="font-16-mobile"
          className="result-card-progress-title"
        >
          {props.skillname}
        </Text>
        <div className={"result-card-progress-bar-and-percentage"}>
          <div className={"result-card-progress-bar"}>
            <animated.div
              className={"result-card-progress-bar-fill"}
              style={progressSpringStyleProps}
            />
          </div>
          <Text
            Tag="div"
            fontSize={"font-14"}
            textType="roman"
            mobileFontSize="font-15-mobile"
            className="result-card-progress-percentage"
          >
            %{props.percent}
          </Text>
        </div>
      </div>
    </InView>
  );
}
function getElementsArray(array) {
  return (
    array &&
    array.map((item, i) => (
      <SkillProgress
        key={i}
        percent={Math.round(item.score)}
        skillname={item.name}
      />
    ))
  );
}
const ResultCard = ({
  finalResult,
  results,
  closingDays,
  isShowResults = true,
  isBeforeAudit = false,
}) => {
  return (
    <div className="result-card-wrapper">
      {!isShowResults ? (
        <div className="result-card-header result-card-horizontal-padding__before_result result-card-image">
          <img src={"img/certificationStep7.svg"} />
          <Text
            Tag="div"
            fontSize={"font-20"}
            mobileFontSize="font-16-mobile"
            textType="roman"
            className="result-card-image__title"
          >
            جاري التدقيق
          </Text>
          <Text
            Tag="div"
            fontSize={"font-16"}
            textType="roman"
            className="result-card-image__description"
          >
            ستظهر هنا النتيجة النهائية بعد الانتهاء من عملية التدقيق
          </Text>
        </div>
      ) : (
        <Fragment>
          <div className="result-card-header">
            <div className="result-card-horizontal-padding__after_result ">
              <Text
                Tag="div"
                fontSize={"font-18"}
                mobileFontSize="font-16-mobile"
                textType="bold"
                className="result-card-progress-title"
              >
                النسبة الكلية
              </Text>
              <Text
                Tag="div"
                fontSize={"font-14"}
                textType="roman"
                mobileFontSize="font-15-mobile"
                className="result-card-progress-title "
              >
                {isBeforeAudit ? "قبل التدقيق" : "بعد التدقيق"}
              </Text>
            </div>
            <Text
              Tag="div"
              fontSize={"font-36"}
              textType="roman"
              mobileFontSize="font-28-mobile"
              className={`result-card-progress-header-percentage ${
                finalResult > 75
                  ? "result-card-progress-header-percentage__green"
                  : finalResult < 75 && finalResult > 30
                  ? "result-card-progress-header-percentage__orange"
                  : closingDays > 0 || finalResult < 30
                  ? "result-card-progress-header-percentage__red"
                  : ""
              }`}
            >
              %{Math.round(finalResult)}
            </Text>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="result-card-horizontal-padding__after_result ">
            {getElementsArray(results)}
          </div>
        </Fragment>
      )}
    </div>
  );
};
ResultCard.propTypes = {
  finalResult: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  results: PropTypes.array.isRequired,
  closingDays: PropTypes.number,
  isShowResults: PropTypes.bool,
  isBeforeAudit: PropTypes.bool,
};

export default ResultCard;
