import React, { useEffect, useState } from "react";
import Content from "../../containers/Content/Content";
import "./ContactUsPage.css";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import { Text } from "../../../../shared-components/my-scope/atoms/text";
import ContactItem from "./ContactItem";
import Button from "../Button/Button";
import LabeledDropDown from "../LabeledDropDown/LabeledDropDown";
import { arabicAndEnglishLettersOnly } from "../../../../frontend-admin/src/utilities/helpers";
import {
  digitsOnly,
  emailOnly,
  isEmailFormatOnly,
  isPhoneNumberFormat,
  lettersWithNumbersAndSpecialCharacters,
} from "../../../utilities/helpers";
import TitledTextInput from "../TitledTextInput/TitledTextInput";
import TextArea from "../common/TextArea";
import DropZone from "../DropZone/DropZone";
import { jiraHelpWidget } from "../../actions/jiraWidgetActions";
import { useDispatch } from "react-redux";
import PopUp from "../../views/Components/PopUp/PopUp";
import Loader from "../Loader/Loader";

const ContactUsPage = (props) => {
  const [formData, setFormData] = useState({
    requestType: "",
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
    attachments: [],
    attachmentsDropZoneList: [],
  });
  const [formErrors, setFormErrors] = useState({
    emailError: "",
    phoneNumberError: "",
    requestTypeError: "",
    descriptionError: "",
    nameError: "",
  });
  const [ticketNumber, setTicketNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const isFieldsError = isFieldError();
    if (!isFieldsError) {
      setIsLoading(true);
      dispatch(
        jiraHelpWidget(
          formData.name,
          formData.email,
          formData.requestType?.value,
          formData.description,
          formData.attachments,
          formData.phoneNumber
        )
      )
        .then((tickNum) => {
          setIsLoading(false);

          setTicketNumber(tickNum);
          console.log("tickNum", tickNum);
          if (tickNum) {
            toastr.success("تم الإرسال بنجاح");
          } else {
            toastr.error("فشل في إنشاء التذكرة");
          }
        })
        .catch(() => {
          setIsLoading(false);
          toastr.error("فشل في إنشاء التذكرة");
        });
    }
  };

  const emailValidation = () => {
    if (!formData.email) {
      return "الرجاء إدخال البريد الإلكتروني";
    } else {
      if (!isEmailFormatOnly(formData.email)) {
        return "الرجاء إدخال بريد إلكتروني صحيح";
      }
    }
    return null;
  };

  const numberValidation = () => {
    if (!formData.phoneNumber || formData.phoneNumber.trim().length === 0) {
      return "الرجاء إدخال رقم التواصل";
    } else if (!isPhoneNumberFormat(formData.phoneNumber)) {
      return "الرجاء إدخال رقم تواصل يبدأ بـ05";
    } else return null;
  };

  const nameValidation = () => {
    if (!formData.name || formData.name.trim().length === 0) {
      return "الرجاء إدخال الاسم";
    } else return null;
  };
  const descriptionValidation = () => {
    if (!formData.description || formData.description.trim().length === 0) {
      return "الرجاء إدخال الوصف";
    } else return null;
  };
  const requestTypeValidation = () => {
    if (formData.requestType === null || formData.requestType === "") {
      return "الرجاء اختيار نوع الطلب";
    } else return null;
  };

  const isFieldError = () => {
    let errors = { ...formErrors };

    errors.nameError = nameValidation();

    errors.emailError = emailValidation();

    errors.phoneNumberError = numberValidation();

    errors.requestTypeError = requestTypeValidation();

    errors.descriptionError = descriptionValidation();

    setFormErrors(errors);
    if (
      errors.nameError ||
      errors.emailError ||
      errors.phoneNumberError ||
      errors.requestTypeError ||
      errors.descriptionError
    ) {
      return true;
    } else return false;
  };

  const dropZoneText = (
    <div className="attachment-box-content">
      <div className="attachment-box-text">
        <div className="attachment-box-line">
          <Text Tag="span" textType="p5">
            قم بسحب الملف أو{" "}
          </Text>
          <Text Tag="span" textType="p5" className="attachment-box-text__green">
            تحديده من جهازك
            <img src={"img/upload2.svg"} alt="Upload" />
          </Text>
        </div>

        <Text Tag="div" textType="p7" className="attachment-box-file-types">
          jpeg, png, bmp, jpg, pdf
        </Text>
        <Text Tag="div" textType="p7" className="attachment-box-size-limit">
          الحد الأقصى لحجم الملف هو 5 ميقا
        </Text>
      </div>
    </div>
  );

  const remove = (fileKey) => {
    setFormData((prevData) => ({
      ...prevData,
      attachmentsDropZoneList: formData.attachmentsDropZoneList.filter(
        (id) => id !== fileKey
      ),
    }));
  };

  const onNewAddAttachmentClick = (event) => {
    event.preventDefault();
    if (formData.attachmentsDropZoneList.length < 4) {
      setFormData((prevData) => ({
        ...prevData,
        attachmentsDropZoneList: [
          ...formData.attachmentsDropZoneList,
          new Date().getTime(),
        ],
      }));
    } else {
      toastr.error("لا يمكنك إضافة أكثر من ٥ مرفقات");
    }
  };

  return (
    <Content history={props.history}>
      <div className="container-fluid-size ">
        <div className="justify-content-center">
          <div className="animated fadeIn">
            <TitleWithSubTitle subtitle="تواصل معنا" />
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-35"}
              mobileFontSize={"font-18-mobile"}
              className="frame-contact-us-sub-title-text"
            >
              نحن متواجدون لخدمتك عبر القنوات التالية
            </Text>
            <div className="frame-contact-us-cards-section">
              <ContactItem
                iconSrc="img/x.svg"
                headingText="منصة X"
                paragraphText="تواصل معنا عبر منصة التواصل الاجتماعي X"
                linkHref="https://x.com/ims_hrsd?lang=ar"
                linkText="Ims_hrsd@"
                linkType="link"
              />

              <ContactItem
                iconSrc="img/feather-mail.svg"
                headingText="البريد الإلكتروني"
                paragraphText="نسعد بتواصلك معنا عبر البريد الالكتروني"
                linkHref="ims@takamol.com.sa"
                linkText="ims@takamol.com.sa"
                linkType="email"
              />

              <ContactItem
                iconSrc="img/contact-phone.svg"
                headingText="الرقم الموحد"
                paragraphText="يمكنك التواصل معنا"
                linkHref="920033411"
                linkText="920033411"
                linkType="phone"
              />
            </div>
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-40"}
              mobileFontSize={"font-22-mobile"}
              className="ticket-title"
            >
              رفع تذكرة
            </Text>
            <PopUp
              isOpen={ticketNumber}
              title="تم انشاء التذكره بنجاح"
              body={`رقم التذكرة ${ticketNumber}`}
              onClickCancel={() => setTicketNumber(null)}
              cancelButtonText="حسنًا"
            />
            {isLoading ? (
              <div className="contact-us-loader">
                <Loader />
              </div>
            ) : (
              <div className="tickect-form-section">
                <Text
                  Tag="div"
                  textType="roman"
                  fontSize={"font-22"}
                  mobileFontSize={"font-27-mobile"}
                  className="form-text"
                >
                  يرجى تعبئة البيانات التالية مع كتابة وصف لطلبك و سيقوم فريقنا
                  بالتواصل معك قي أقرب وقت.
                </Text>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-feilds">
                    <LabeledDropDown
                      label="نوع الطلب"
                      placeholder="نوع الطلب"
                      value={formData.requestType}
                      onChange={(item) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          requestType: item,
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          requestTypeError: "",
                        }));
                      }}
                      options={[
                        {
                          label: "استفسار",
                          value: 4,
                        },
                        {
                          label: "الدعم الفني",
                          value: 8,
                        },
                      ]}
                      errorText={formErrors.requestTypeError}
                    />

                    <TitledTextInput
                      title="الاسم"
                      type="text"
                      onChange={(item) => {
                        const nameInput = arabicAndEnglishLettersOnly(
                          item.target.value
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          name: nameInput,
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          nameError: "",
                        }));
                      }}
                      errorText={formErrors.nameError}
                      placeholder="الاسم"
                      value={formData.name}
                      name="name"
                      onFocus={() => {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          nameError: "",
                        }));
                      }}
                    />
                    <TitledTextInput
                      title="رقم التواصل"
                      type="text"
                      onChange={(item) => {
                        const numberInput = digitsOnly(item.target.value);
                        setFormData((prevData) => ({
                          ...prevData,
                          phoneNumber: numberInput,
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          phoneNumberError: "",
                        }));
                      }}
                      placeholder="05xx-xxx-xxxx"
                      value={formData.phoneNumber}
                      name="phoneNumber"
                      maxLength="10"
                      errorText={formErrors.phoneNumberError}
                    />
                    <TitledTextInput
                      title="البريد الإلكتروني"
                      type="text"
                      onChange={(item) => {
                        const emailInput = emailOnly(item.target.value);
                        setFormData((prevData) => ({
                          ...prevData,
                          email: emailInput,
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          emailError: "",
                        }));
                      }}
                      placeholder="البريد الإلكتروني"
                      value={formData.email}
                      name="email"
                      maxLength="70"
                      errorText={formErrors.emailError}
                    />
                  </div>

                  <TextArea
                    name="description"
                    label="الوصف"
                    isResizable={false}
                    placeholder="يرجى كتابة تفاصيل طلبك"
                    onChange={(item) => {
                      const descriptionInput =
                        lettersWithNumbersAndSpecialCharacters(
                          item.target.value
                        );
                      setFormData((prevData) => ({
                        ...prevData,
                        description: descriptionInput,
                      }));
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        descriptionError: "",
                      }));
                    }}
                    value={formData.description}
                    errorText={formErrors.descriptionError}
                    customWrapper={"help-widget-text-area-custom-wrapper"}
                  />

                  <Text
                    Tag="div"
                    textType="roman"
                    fontSize={"font-16"}
                    className="help-widget-attachments__title"
                  >
                    المرفقات
                    <Text Tag="span" textType="light" fontSize={"font-15"}>
                      {" "}
                      (اختياري)
                    </Text>
                  </Text>
                  <DropZone
                    DropZoneTitle={dropZoneText}
                    uploadAttachment={(val) => {
                      const list = formData.attachments.concat(val);

                      setFormData((prevData) => ({
                        ...prevData,
                        attachments: list,
                      }));
                    }}
                  />
                  {formData.attachmentsDropZoneList.map((id) => (
                    <div key={id} className="help-widget-attachments__wrapper">
                      <div className="help-widget-attachments__drop-zone">
                        <DropZone
                          DropZoneTitle={dropZoneText}
                          uploadAttachment={(val) => {
                            const list = formData.attachments.concat(val);

                            setFormData((prevData) => ({
                              ...prevData,
                              attachments: list,
                            }));
                          }}
                        />
                      </div>
                      <div className="help-widget-attachments__delete-button-wrapper">
                        <i
                          onClick={() => remove(id)}
                          id="add-new-attachment-button"
                          className="help-widget-attachments__delete-button-link"
                        >
                          <img
                            src={"img/delete.svg"}
                            alt="button icon"
                            className="help-widget-attachments__delete-button-image"
                          />
                        </i>
                      </div>
                    </div>
                  ))}
                  <Button
                    text="إضافة مرفق"
                    iconLeft={"img/plus.svg"}
                    customTextSize="roman"
                    fontSize="font-14"
                    mobileFontSize="font-14-mobile"
                    onClick={onNewAddAttachmentClick}
                    customStyle="help-widget-add-button"
                    buttonIconCustomStyle="help-widget-add-button-icon"
                    id="add-attachment-button"
                  />

                  <button type="submit" className="submit-button">
                    إرسال
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ContactUsPage;
