import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Row } from "reactstrap";

import CaliberAnswer from "./CaliberAnswer";
import CaliberParagraph from "./CaliberParagraph";
import CaliberAttachment from "./CaliberAttachment";
import CaliberDescription from "./CaliberDescription";

import "./caliber.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";
import CaliberAuditComments from "./CaliberAuditComments";
import { PrevAnswerInDetailedReport } from "../../../../components/establishmentprofile/PrevAnswerInDetailedReport";
import { ViolationDetails } from "./ViolationDetails";
import { AttachmentUploader } from "../../AssessmentFeature/self-components/components/attachments/AttachmentUploader";
import { AttachmentIcon } from "../../AssessmentFeature/lib/assets/icons/icons";

export const Caliber = ({
  caliberCategoryIndex,
  caliberIndex,
  standardCategoryIndex,
  caliber,
  assessmentType,
  isShowAuditResult,
}) => {
  const isFirstItem =
    !caliberCategoryIndex && !caliberIndex && !standardCategoryIndex;
  const [isOpen, setIsOpen] = useState(isFirstItem);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const isShowAuditSection = isShowAuditResult;
  const isShowAuditAttachments =
    isShowAuditSection &&
    caliber.audit_answer &&
    caliber.audit_answer.attachments &&
    caliber.audit_answer.attachments.length > 0;
  const isShowAuditComments =
    isShowAuditSection &&
    caliber.audit_answer &&
    caliber.audit_answer.all_audit_comments &&
    caliber.audit_answer.all_audit_comments.length > 0;
  const auditAnswer = caliber.audit_answer || {};
  const caliberEstablishmentAttachment =
    caliber.is_answered === "answered"
      ? caliber.new_attachments
      : caliber.attachments;

  const violationNumber =
    caliber?.is_answered === "answered"
      ? caliber.audit_answer?.new_violation_number
      : caliber.audit_answer?.violation_number;

  const establishmentNote =
    caliber.is_answered === "answered"
      ? caliber.audit_answer?.new_note
      : caliber.audit_answer?.note;

  return (
    <div className="caliber">
      <header
        onClick={toggle}
        className="caliber-header"
        id={`caliber-${caliberIndex + 1}-header-button`}
      >
        <Text
          textType="roman"
          fontSize="font-20"
          mobileFontSize="font-16-mobile"
          className="caliber-header__text"
        >{`البند ${caliberCategoryIndex + 1}.${caliberIndex + 1}`}</Text>
        <i
          className={`caliber-header__icon fa fa-chevron-${
            isOpen ? "up" : "down"
          }`}
        />
      </header>
      <Collapse isOpen={isOpen}>
        <main className="caliber-main">
          <div className="caliber-mainــpadding">
            {isShowAuditResult && caliber.name ? (
              <CaliberParagraph title="شرح البند" desc={caliber.name.String} />
            ) : (
              <CaliberParagraph title="شرح البند" desc={caliber.name} />
            )}

            {caliber.corrections && (
              <CaliberParagraph
                title="المرجعية النظامية"
                desc={caliber.corrections}
              />
            )}
            {caliber.description && (
              <CaliberDescription description={caliber.description} />
            )}
            {/* {caliber.reference && (
            <CaliberParagraph
              title="رقم المرجعية النظامية"
              desc={caliber.reference}
            />
          )} */}
            {/* <div className="caliber-description">
            <header className="caliber-description-header">
        <Text
          textType="p4"
          isBold
          className="caliber-description-header__title"
        >
          تم ارجاع هذا البند وتعديل إجابته من قبل المنشأة
        </Text>
      </header>
      </div> */}
            {caliber.is_answered !== null && (
              <PrevAnswerInDetailedReport
                attachments={caliber.attachments}
                answer={caliber.answer}
                reason={caliber?.caliber_return_reason_name}
                details={caliber?.return_caliber_details}
                caliber={caliber}
              />
            )}

            <Row>
              <Col>
                <div
                  className="horizontal-line"
                  style={{
                    marginTop: 38,
                    marginBottom: 25,
                    marginRight: 6,
                    marginLeft: 6,
                    borderTop: "1px solid #74727233",
                  }}
                ></div>
              </Col>
            </Row>
            {caliber.answer && (
              <CaliberAnswer
                answer={
                  caliber.is_answered !== null
                    ? caliber.new_answer
                    : caliber.answer
                }
                isAnswerAfterUpdate={
                  caliber.is_answered !== null &&
                  caliber.is_answered !== "not_answered"
                }
                auditAnswer={caliber.audit_answer}
              />
            )}
            {caliber.audit_answer && (
              <ViolationDetails
                violation_number={violationNumber}
                note={establishmentNote}
              />
            )}
            {caliberEstablishmentAttachment?.filter(
              (attach) => attach?.file_name?.length > 0
            )?.length > 0 && (
              <div className="flex items-center gap-x-2  attachments-title">
                <AttachmentIcon className="w-4 h-4" />
                <Text
                  Tag={"div"}
                  textType="bold"
                  fontSize={"font-18"}
                  mobileFontSize={"font-16-mobile"}
                  style={{ color: "#14415A" }}
                  className="mb-0"
                >
                  {"المرفقات"}
                </Text>
              </div>
            )}
            {caliberEstablishmentAttachment?.length
              ? caliberEstablishmentAttachment?.map?.((attachment, idx) => {
                  return attachment.file_name !== "" ? (
                    <AttachmentUploader
                      key={idx}
                      file={attachment}
                      name={attachment?.name}
                      index={idx}
                      isShowDelete={false}
                    />
                  ) : (
                    ""
                  );
                })
              : null}
          </div>
          {isShowAuditResult && (
            <div className="caliber-main__audit">
              {caliber.audit_answer && caliber.audit_answer.auditor_answer && (
                <CaliberAnswer
                  answer={caliber.audit_answer.auditor_answer}
                  isAuditor
                />
              )}
              {isShowAuditAttachments &&
                caliber.audit_answer.attachments?.filter(
                  (attach) => attach?.file_name?.length > 0
                )?.length > 0 && (
                  <div className="flex items-center gap-x-2  attachments-title">
                    <AttachmentIcon className="w-4 h-4" />
                    <Text
                      Tag={"div"}
                      textType="bold"
                      fontSize={"font-18"}
                      mobileFontSize={"font-16-mobile"}
                      style={{ color: "#14415A" }}
                      className="mb-0"
                    >
                      {"المرفقات"}
                    </Text>
                  </div>
                )}
              {isShowAuditAttachments &&
                caliber.audit_answer.attachments?.map?.((attachment, idx) => {
                  return (
                    <AttachmentUploader
                      key={idx}
                      file={attachment}
                      name={attachment?.file_name}
                      index={idx}
                      isShowDelete={false}
                    />
                  );
                })}
              {isShowAuditComments && (
                <CaliberAuditComments
                  comments={caliber.audit_answer.all_audit_comments}
                />
              )}
            </div>
          )}
        </main>
      </Collapse>
    </div>
  );
};
Caliber.propTypes = {
  caliberCategoryIndex: PropTypes.number.isRequired,
  caliberIndex: PropTypes.number.isRequired,
  standardCategoryIndex: PropTypes.number.isRequired,
  caliber: PropTypes.object.isRequired,
  assessmentType: PropTypes.string.isRequired,
  isShowAuditResult: PropTypes.bool.isRequired,
};

export default Caliber;
