import React from "react";

export const getSummaryDescription = (
  isStartFreeAssessment,
  isReturnedAssessment,
  isPayForAssessment,
  isBlocked,
  isUnderAuditing,
  isCompleted,
  isFastTrackAssessmentAvailable,
  isGoToFastTrackAssessment,
  isReAssessmentSubmitted,
  isFastTrackAssessment,
  isReassessment,
  isCompletedAndCertified,
  isCompletedAndNotCertified
) => {
  if (isCompletedAndCertified) {
    return `عميلنا العزيز، نفيدكم بظهور النتيجة النهائية للتقييم و إصدار شهادة الامتثال بنجاح، كما يمكنكم الاطلاع على نتيجة التقييم و التقرير المفصل وذلك من خلال الانتقال إلى صفحة نتيجة التقييم`;
  } else if (isCompletedAndNotCertified) {
    return `عميلنا العزيز، نفيدكم بظهور النتيجة النهائية للتقييم، كما يمكنكم الاطلاع على نتيجة التقييم و التقرير المفصل وذلك من خلال الانتقال إلى صفحة نتيجة التقييم`;
  }
  if (
    // if blocked but make assessment
    (isBlocked && !isUnderAuditing && !isCompleted) ||
    isStartFreeAssessment ||
    isPayForAssessment ||
    isReturnedAssessment ||
    isGoToFastTrackAssessment
  ) {
    return ` تهدف خدمة التقييم الذاتي لرفع نسبة الامتثال للمنشأة وذلك بمعرفة نسبة
 امتثالها لأنظمة و لوائح وزارة الموارد البشرية و التنمية الاجتماعية مما
 يساعدها في تجنب المخالفات والمساعدة على تصحيحها.
`;
  } else if (isPayForAssessment || (isReassessment && !isReAssessmentSubmitted))
    return "يمكنك من خلال هذه الخدمة الحصول على شهادة امتثال لمنشأتك مقدمة من قِبل وزارة الموارد البشرية والتمنية الاجتماعية والتي يمكن اعتبارها كإثبات معتمد بأن المنشأة ملتزمة بالمعايير المحددة.";
  else if (isUnderAuditing) {
    return `نشكرك على المسارعة لإكمال التقييم. بإمكانك الان الاستفادة من خدمات الوزارة ومنصة قوى والاطلاع على نتيجة التقييم المبدئية من خلال صفحة نتيجة التقييم`;
  } else if (isCompleted || isReAssessmentSubmitted) {
    return "عميلنا العزيز، نفيدكم بظهور النتيجة النهائية للتقييم و يمكنكم الاطلاع عليها من خلال صفحة نتيجة التقييم";
  } else if (
    isFastTrackAssessment &&
    (isFastTrackAssessmentAvailable || !isFastTrackAssessmentAvailable)
  ) {
    return "يمكنك من خلال هذه الخدمة الحصول على شهادة امتثال لمنشأتك مقدمة من قِبل وزارة الموارد البشرية والتمنية الاجتماعية والتي يمكن اعتبارها كإثبات معتمد بأن المنشأة ملتزمة بالمعايير المحددة.";
  }
};

export const getSummaryButtonText = (
  isStartFreeAssessment,
  isReturnedAssessment,
  isPayForAssessment,
  isBlocked,
  isUnderAuditing,
  isCompleted,
  isFastTrackAssessmentAvailable,
  isGoToFastTrackAssessment,
  isReAssessmentSubmitted,
  isContinueAssessment,
  isReassessment
) => {
  if (isReassessment) {
    if (isContinueAssessment) {
      return "اكمل التقييم";
    } else if (!isReAssessmentSubmitted) {
      return "بدء إعادة التقييم";
    }
  }
  if (
    (isBlocked && !isUnderAuditing && !isCompleted) ||
    isStartFreeAssessment ||
    isGoToFastTrackAssessment
  ) {
    if (isContinueAssessment) {
      return "اكمل التقييم";
    } else {
      return "بدء التقييم";
    }
  } else if (isReturnedAssessment) return "مراجعة النموذج";
  else if (isContinueAssessment) return "اكمل التقييم";
  else if (isPayForAssessment) return "طلب الخدمة";
  else if (isUnderAuditing || isCompleted || isReAssessmentSubmitted) {
    return "نتيجة التقييم";
  } else if (!isFastTrackAssessmentAvailable) {
    return "طلب الخدمة";
  }
};

export const getSummaryImage = (
  isStartFreeAssessment,
  isReturnedAssessment,
  isPayForAssessment,
  isBlocked,
  isUnderAuditing,
  isCompleted,
  isFastTrackAssessment,
  isFastTrackAssessmentAvailable,
  isGoToFastTrackAssessment,
  isReassessment,
  isReAssessmentSubmitted,
  isCompletedAndCertified,
  isCompletedAndNotCertified
) => {
  if (
    (isPayForAssessment && isFastTrackAssessment) ||
    (!isUnderAuditing && isFastTrackAssessment) ||
    (!isUnderAuditing && isFastTrackAssessmentAvailable) ||
    (isReassessment && !isReAssessmentSubmitted)
  )
    return "img/assessment-laptop-certificate.svg";
  else if (
    (isBlocked && !isUnderAuditing && !isCompleted) ||
    isStartFreeAssessment ||
    isPayForAssessment ||
    isReturnedAssessment ||
    isGoToFastTrackAssessment
  ) {
    return "img/assessment-laptop.svg";
  } else if (isUnderAuditing) {
    return "img/assessment-auditing-laptop.svg";
  } else if (
    isCompleted ||
    isReAssessmentSubmitted ||
    isCompletedAndCertified ||
    isCompletedAndNotCertified
  )
    return "img/assessment-laptop2.svg";
};

// export const getSummaryButtonText = (
//   isStartFreeAssessment,
//   isReturnedAssessment,
//   isPayForAssessment,
//   isBlocked,
//   isUnderAuditing,
//   isCompleted
// ) => {
//   if (isBlocked || isStartFreeAssessment) {
//     return "بدء التقييم";
//   } else if (isReturnedAssessment) return "مراجعة النموذج";
//   else if (isPayForAssessment) return "طلب الخدمة";
//   else if (isUnderAuditing || isCompleted) {
//     return history.push(
//       `/establishmentAssessmentResult/${duration.id}/${duration.assessment.id}/${profileId}/${duration.assessment_version}`
//     );
//   }
// };
