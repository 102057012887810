import React, { useEffect, useState } from "react";
import { unstable_renderSubtreeIntoContainer } from "react-dom/client";
import { string, element, oneOfType, array } from "prop-types";
import { useGoogleMap } from "@react-google-maps/api";

function MapControl(children = [], className = "", position) {
  const [map, setMap] = useState(useGoogleMap());

  const controlDiv = document.createElement("div");
  const divIndex = map.controls[position].length;
  map.controls[position].push(controlDiv);

  useEffect(
    () => {
      className && controlDiv.classList.add(className);
      unstable_renderSubtreeIntoContainer(
        this,
        React.Children.only(children),
        controlDiv
      );
    },
    map.controls[position].removeAt(divIndex),
    []
  );

  return false;
}
MapControl.propTypes = {
  position: string.isRequired,
  children: oneOfType([element, array]),
  className: string,
};
