export const STATUS_BLOCKED = "Blocked";
export const STATUS_UNBLOCKED = "Unblocked";
export const STATUSES = {
  [STATUS_BLOCKED]: "موقوفة",
  [STATUS_UNBLOCKED]: "نشطة",
};
export const getStatusColor = (isBlocked) => {
  if (isBlocked) {
    return "red";
  } else {
    return "green";
  }
};
