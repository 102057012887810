import * as React from "react";

export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.618"
    height="21.174"
    viewBox="0 0 21.618 21.174"
    {...props}
  >
    <g id="upload_icon" data-name="upload icon" transform="translate(1 1)">
      <path
        id="Rectangle_9"
        data-name="Rectangle 9"
        d="M19.618,0V3.6H0V0"
        transform="translate(0 15.579)"
        fill="none"
        stroke="#92929d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Line"
        d="M0,7.19,7.357,0V14.38"
        transform="translate(2.452)"
        fill="none"
        stroke="#92929d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Line_Copy"
        data-name="Line Copy"
        d="M0,0,3.065,3.6"
        transform="translate(14.1 3.595)"
        fill="none"
        stroke="#92929d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
