export const faq = {
  title: "الأسئلة الشائعة",
  cards: [
    {
      title: "ما هي منصة التقييم الذاتي؟",
      description:
        "هي منصة تهدف إلى تحقيق أعلى درجات الامتثال لأنظمة وزارة الموارد البشرية والتنمية الاجتماعية من خلال حلقةٍ متكاملة تبدأ بالتقييم الذاتي والعمل على تصحيح أخطائها قبل الزيارة التفتيشية من قبل مفتّشي وزارة الموارد البشرية والتنمية الاجتماعية.",
    },
    {
      title: "كيف سأستفيد من المنصة",
      description: [
        {
          subtitle: "كل ما عليك هو القيام بالآتي:",
          subDescription: [
            " من خلال المنصة ستتعرف المنشآت على جميع مخالفاتها - إن وجدت - لأنظمة وزارة الموارد البشرية والتنمية الاجتماعية.",
            " حمايتها من الوقوع في المخالفات قبل زيارة مفتّشي وزارة الموارد البشرية والتنمية الاجتماعية.",
            " إعطاء الفرصة للمنشآت لقياس نسبة امتثالها للوائح نظام العمل السعودي.",
            " إبقاء المنشآت على علمٍ بتحديثات نظام العمل السعودي.",
            " رفع الوعي لدى المنشآت فيما يخصّ متطلّبات نظام العمل.",
          ],
          steps: false,
        },
      ],
    },
    {
      title: "لماذا يتوجب على المنشأة القيام بالتقيّيم الذاتي؟",
      description:
        "التقييم الذاتي يساعد المنشأة على التعرّف بأنظمة وقوانين وزارة الموارد البشرية والتنمية الاجتماعية وتصحيح الأخطاء ذاتيًّا مما يحميها من الوقوع في المخالفات، كما أنه متطلّب للاستفادة من خدمات الوزارة.",
    },
    {
      title: "كيفية إجراء التقييم للمنشأة؟",
      description: [
        {
          subtitle: "كل ما عليك هو القيام بالآتي:",
          subDescription: [
            "	التوجّه إلى الصفحة الرئيسية واختيار خيار تسجيل الدخول باستخدام بيانات المنشأة لدى وزارة الموارد البشرية والتنمية الاجتماعية (الدخول الموحد لخدمات الوزارة) باستخدام الهوية الوطنية أو الإقامة.",
            "	بعد الموافقة على الشروط والأحكام.",
            '	يتطلب على ممثل المنشأة التقييم من خلال النقر على "التقييم الذاتي" من القائمة الجانبية.',
            ' لبدء مرحلة التقييم الذاتي يرجى النقر على ايقونة "طلب الخدمة".',
            "	يستعرض النظام تفاصيل خدمة التقييم الذاتي في حال الرغبة في بدء التقييم الذاتي سوف تظهر صفحة السداد لبدء التقييم الذاتي كما يمكنك السداد باستخدام سداد أو بطاقات الائتمان فيزا - ماستير كارد، مدى، وغيرها من البطاقات. بعد سداد رسوم الخدمة يمكنك البدء بالتقييم الذاتي.",
            "	عند بدء التقييم ابدأ بالإجابة على الأسئلة الخاصة بمنشأتك.",
          ],
          steps: false,
        },
        {
          subtitle:
            "في حال لم يكن لديك حساب لدى وزارة الموارد البشرية والتنمية الاجتماعية:",
          subDescription: [
            'قم بإنشائه أولًا في بوابة الخدمات الإلكترونية لوزارة الموارد البشرية والتنمية الاجتماعية من خانة "إنشاء حساب" ثم اتبع الخطوات المذكورة أعلاه.',
          ],
          steps: false,
        },
      ],
    },
    {
      title: "هل أستطيع تغيير إجاباتي في وقت لاحق بعد إنهاء التقييم؟",
      description:
        "لا، لا يمكن تغيير الإجابات للتقييم الذاتي بعد الانتهاء منه.",
    },
    {
      title:
        "هل سيترتب على المنشأة أيّ غراماتٍ مالية إذا لم تكن ملتزمةً بأحد معايير وزارة الموارد البشرية في مرحلة التقييم الذاتي؟",
      description: "في الفترة الحاليّة لن يترتب على المنشأة أيّ غرامات مالية.",
    },
    {
      title: "ماذا لو كانت نتائج التقييم الذاتي للمنشأة غير مرضية؟",
      description:
        "الهدف من مرحلة التقييم الذاتي أن تتعرّف المنشأة على نسبة امتثالها لأنظمة العمل ثمّ العمل على تصحيح الأخطاء والمخالفات، ولن يترتب على المنشأة أي غرامات مالية في هذه المرحلة.",
    },
    {
      title:
        "هل يجب على المنشأة الالتزام بجميع معايير وأنظمة وزارة الموارد البشرية والتنمية الاجتماعية؟",
      description:
        "نعم، في حال كان المعيار ينطبق على المنشأة فيجب الالتزام بذلك حسب أنظمة وزارة الموارد البشرية.",
    },
    {
      title: "هل يوجد حدٌّ أدنى للامتثال؟",
      description:
        "لا، لا يوجد حدٌّ أدنى للامتثال، بل يجب على المنشأة الالتزام بجميع معايير وأنظمة وزارة الموارد البشرية والتنمية الاجتماعية.",
    },
    {
      title: "كيف تطّلع المنشأة على نتائج التقييم الذاتي فيما بعد؟",
      description:
        'يمكن للمنشأة الاطلاع على نتائج التقييم الذاتي بتسجيل الدخول إلى البوابة واختيار التقييم الذاتي من القائمة الرئيسية حيث يقوم النظام بتحويل المستخدم إلى صفحة التقييم ومن ثم يقوم المستخدم باختيار الفترة التقييمية والنقر على "عرض نتيجة التقييم".',
    },
    {
      title: 'ماذا يقصد بخيار "لا ينطبق" عند الإجابة على التقييم الذاتي؟',
      description:
        'يُستخدم هذا الخيار عندما لا تنطبق إحدى معايير وزارة الموارد البشرية والتنمية الاجتماعية على المنشأة، على سبيل المثال: الشركة تعمل في مجال الصناعة وليس لها علاقة بما يتعلّق بالمعايير المخصصة للبحّارة، يتم اختيار "لا ينطبق" مع ذكر التبرير.',
    },
    {
      title: "ماذا يحصل إذا لم تلتزم المنشأة بأحد البنود تحت أحد المعايير؟",
      description:
        "إذا لم تلتزم المنشأة بأحد البنود تحت إحدى المعايير فإنه يعد امتثال المنشأة ناقصا لذلك المعيار.",
    },
    // {
    //   title: "كيف تقوم المنشأة بتسجيل الدخول؟",
    //   description: [
    //     {
    //       subtitle:
    //         "بالتوجّه إلى الصفحة الرئيسية لموقع منصة التقييم الذاتي ثم:",
    //       subDescription: [
    //         "الضغط على تسجيل الدخول إذا كنت تمتلك بيانات دخول في بوابة الخدمات الإلكترونية لوزارة الموارد البشرية والتنمية الاجتماعية.",
    //         'اذا كنت لا تمتلك حسابًا، فيمكنك "التسجيل" عن طريق بوابة الخدمات الإلكترونية لوزارة الموارد البشرية والتنمية الاجتماعية. https://www.mol.gov.sa/IndividualUser/BasicInfo.aspx',
    //       ],
    //       steps: false,
    //     },
    //   ],
    // },
    {
      title: "من هو الشخص المفوّض؟",
      description:
        "هو الشخص الذي تمّ تفويضه في حساب المنشأة لدى بوابة الخدمات الالكترونية الخاصة بوزارة الموارد البشرية والتنمية الاجتماعية.",
    },
    {
      title: "كيف أستطيع التواصل مع فريق منصة التقييم الذاتي؟",
      description: [
        {
          subtitle: " نسعد بخدمتك عبر التواصل معنا عن طريق:",
          subDescription: [
            "  البريد الإلكتروني ims@takamol.com.sa",
            " أو عبر منصة اكس IMS_HRSD@",
            " أو التواصل الهاتفي لخدمة العملاء عبر الرقم 920033411",
          ],
          steps: false,
        },
      ],
    },
    {
      title: "لم تجد الإجابة على سؤالك؟",
      description:
        "يقوم المستخدم بتحديد فئة السؤال ومن ثم ادخال بيانات المستخدم وعنوان المشكلة وشرح المشكلة وإرفاق المرفقات – إن وجد-",
    },
    {
      title: "مدة التدقيق؟",
      description:
        "يمر التقييم الذاتي بعدة مراحل من المراجعة والتدقيق بعد تقديمه من قبل المنشأة. بعد انتهاء جميع المراحل، سيتمكن العميل من الاطلاع على النتيجة النهائية. مع العلم أنه يمكن الاستفادة من خدمات التوسع في منصة قوى بمجرد إجراء التقييم، وذلك دون انتظار النتيجة النهائية.",
    },
  ],
};
export const aboutProgram = {
  title: "فكرة عن",
  subtitle: "برنامج التقييم الذاتي",
  description:
    "تساعدك منصة التقييم الذاتي في إيجاد بيئة عمل تحقق أعلى درجات الامتثال لنظام العمل السعودي ورفع مستوى الشفافية بين وزارة الموارد البشرية والتنمية الاجتماعية وبين منشأتك",
};
export const programBenefits = {
  title: "أهم",
  subtitle: "خصائص وفوائد البرنامج",
  description: [
    {
      iconTitle: "التعرف على أنظمة ومعايير العمل",
    },
    {
      iconTitle: "تجنب المخالفات والمساعدة على تصحيحها",
    },
    {
      iconTitle: "رفع نسبة الامتثال",
    },
    {
      iconTitle: "رفع نسبة الشفافية",
    },
    {
      iconTitle: "تحسين بيئة العمل",
    },
  ],
};
export const aboutFastTrack = {
  title: "تعرّف على خدمة المسار المميز",
  description:
    "يمكنك من خلال هذه الخدمة الحصول على شهادة الامتثال والتي يمكن إعتبارها كإثبات معتمد من قِبل وزارة الموارد البشرية والتمنية الاجتماعية بأن المنشأة ملتزمة بالمعايير المحددة",
};
export const programStatus = {
  title: "أبرز",
  subtitle: "الأرقام في البرنامج",
  description: [
    {
      number: "89,852+",
      icon: "img/buildingIcon.svg",
      description: "منشأة أكملت التقييم",
    },
    {
      number: "13,000+",
      icon: "img/paperIcon.svg",
      description: "مجموع التقييم الذاتي",
    },
  ],
};
export const whatIsCertification = {
  title: "ما هي",
  subtitle: "شهادة الامتثال",
  description:
    "هي شهادة تحصل عليها المنشأة بعد زيارة مدقق ميداني معتمد والذي يقوم بالتدقيق على اجابات وأدلة  المنشأة المقدمة عن طريق منصة التقييم الذاتي والتأكد من إلتزامها وتقديمها للمعلومات الصحيحة واستيفائها للشروط",
};
export const certificationConditions = {
  title: "شروط",
  subtitle: "الحصول على شهادة الامتثال",
  description: [
    {
      iconTitle: "إكمال التقييم الذاتي",
    },
    {
      iconTitle: "عدم وجود اي مخالفة جسيمة و عالية الجسامة",
    },
    {
      iconTitle: "أن لا تقل نسبة الامتثال عن 80%",
    },
  ],
};
export const certificationBenefits = {
  title: "أهم",
  subtitle: "مميزات شهادة الامتثال",
  description: [
    {
      iconTitle:
        "عدم وجود زيارات تفتيشية من وزارة الموارد البشرية والتنمية الاجتماعية طوال مدة الشهادة ما لم يكن هناك شكوى أو بلاغ",
    },
    {
      iconTitle: "رفع نسبة الإمتثال للمنشآت",
    },
    {
      iconTitle: "زيادة الوعي لدى أصحاب المنشآت ومفوضيها",
    },
    {
      iconTitle: "حماية المنشآت من الوقوع في المخالفات",
    },
    {
      iconTitle:
        "إتاحة الفرصة للقطاع الخاص بالإستفادة من الدور الإستشاري للمدققين.",
    },
  ],
};
export const certificateTypes = {
  title: "فئات",
  subtitle: "شهادة الامتثال",
  description: [
    {
      name: "الفئة الذهبية",
      score: "نتيجة التدقيق 100%",
      duration: "مدة صلاحيتها سنة",
    },
    {
      name: "الفئة الفضية",
      score: "نتيجة التدقيق تتراوح بين (80%- 99%)",
      duration: "مدة صلاحيتها 6 أشهر",
    },
  ],
};
export const certificationSteps = {
  title: "خطوات الحصول",
  subtitle: "على شهادة الامتثال",
  description: [
    {
      step: "رفع المستندات",
      description: "ستظهر للمنشأة أدلة يجب إرفاقها لنموذج التقييم",
      icon: "img/certificationStep3.svg",
    },

    {
      step: "تدقيق مكتبي",
      description: "سيقوم المدقق بعمل التدقيق المكتبي ",
      icon: "img/certificationStep5.svg",
    },

    {
      step: "تدقيق ميداني",
      description: "زيارة المنشأة لعمل التدقيق الميداني على المنشأة",
      icon: "img/certificationStep7.svg",
    },

    {
      step: "إصدار الشهادة",
      description: "الحصول على نتيجة الامتثال",
      icon: "img/certificationStep8.svg",
    },
  ],
};
